import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  chevronIconSize: 'xl',
  logoVariant: 'loyalty',
  showInterestsStep: false,
  profileToastLogo: null,
  showWelcome: false,
  skipButtonVariant: 'secondary',
} satisfies RecursivePartial<DialogConfig['simplifiedEnrollment']>
