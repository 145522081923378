export const useLoyaltyStore = defineStore('loyalty', () => {
  const { consumer } = useApi()
  const { data: vouchers, error, pending, execute } = consumer.getLoyaltyVouchers({ immediate: false })

  const currentReward = computed(() => {
    const currentPoints = vouchers.value?.currentLoyaltyPoints || 0
    const amount = vouchers.value?.applicableLoyaltyVouchers?.reduce((acc, voucher) => {
      return currentPoints >= voucher.points ? Number(voucher.amountInCurrency) : acc
    }, 0) || 0

    return useFormattedPrice(amount, useRuntimeConfig().public.currency[useLocale()], { minimumFractionDigits: 0 })
  })

  const reset = () => {
    vouchers.value = null
  }

  const {
    data: rewardsAvailable,
    pending: rewardsPending,
    error: rewardsError,
    execute: rewardsExecute
  } = consumer.getPaymentInstruments({ payment_method_id: 'REWARD_CODE' }, { immediate: false })

  return {
    currentReward,
    error,
    get: execute,
    getRewards: rewardsExecute,
    pending,
    reset,
    rewardsAvailable,
    rewardsError,
    rewardsPending,
    vouchers,
  }
})
