import type { PdpConfig } from '#types/config/pages/pdp'
import type { RecursivePartial } from '#types/utils'

export default {
  accordions: {
    benefitsAttributeImages: true
  },
  brandClasses: {
    ctasWrapper: 'mt-6 pb-10 space-y-4',
    details: 'lg:col-span-4 md:(col-span-5 row-start-2 row-end-5)',
    detailsSticky: 'md:pt-4',
    eSpotLazyAboveFooter: 'pb-20 <md:(b-t b-grey-70) [&_.container]:px-0',
    eSpotLazyBelowGallery: 'mb-20',
    eSpotsAndReviews: 'space-y-8',
    gallery: '<md:-mx-2 lg:col-span-8 md:(col-span-7 row-start-1 row-end-3 pt-4)',
    grid: 'mb-16 md:(grid mb-20 items-start gap-4 cols-12)',
    gridFullWidth: 'col-span-12',
    gridPartialWidth: 'lg:col-span-8 md:col-span-7',
    heading: 'pt-4 lg:col-span-4 md:col-span-5 <lg:mb-4',
    headingSticky: 'mb-8 lg:mb-4',
    reviewsHeading: 'title-2 md:text-center',
    reviewsSearchInput: 'h-10 b-x-0 b-t-0 b-grey-30 rounded-l-0 rounded-r-0 bg-transparent text-base <lg:grow [&_label]:pl-1',
    reviewsSearchSubmit: 'b-b b-grey-30 bg-transparent px-1',
    reviewsWrapper: 'text-sm space-y-4 md:space-y-6',
    shopTheLookHeading: 'md:(text-center mb-6)',
    shopTheLookWrapper: 'mt-15',
    variantSelectionWrapper: 'b-t b-grey-70 pt-6 space-y-4'
  },
  details: {
    ctaSize: { sm: 'sm', lg: 'md' },
    favoriteCta: {
      iconSize: 'md',
      iconOnly: true
    },
    findInStoreCta: {
      showIcon: false
    }
  },
  enableColorAsAttribute: true,
  gallery: {
    presets: {
      src: {
        width: 462,
        height: 462
      }
    }
  },
  reviews: {
    iconSize: 'md',
    variant: 'tertiary'
  },
  sizeAndFit: {
    showSizeChartIcon: false
  },
  shopTheLook: {
    showLinkArrow: true
  },
  seoMetaImage: '/img/logos/thenorthface/default.svg',
  swatches: {
    showCollapseIcon: true
  },
  upsells: {
    showButton: true,
    showCheckbox: false,
    showDetails: false
  },
  scrollToNotifyMe: true
} satisfies RecursivePartial<PdpConfig>
