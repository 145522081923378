/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { KeysOf } from "#app/composables/asyncData";
import { isMocked, mockPath } from "#root/api/util/mocking";
import type { ApiOptions, UseApiOptions } from "../http-client";
import { HttpClient } from "../http-client";
import type { CollectionPointsData, CollectionPointsPayload, SearchData, SearchPayload } from "./data-contracts";

export class Stores extends HttpClient {
  static paths = { collectionPoints: "/stores/v1/collectionPoints", search: "/stores/v1/search" };
  static mockDataPaths = {};
  static mockedPaths = { collectionPoints: false, search: false };
  static onResponseHandlers = {};
  constructor(baseParams) {
    super(baseParams, "stores");
  }

  /**
   * No description
   *
   * @tags Stores
   * @name CollectionPoints
   * @summary Collection Points
   * @request POST:/stores/v1/collectionPoints
   */
  collectionPoints = <
    ResT = CollectionPointsData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: CollectionPointsPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/stores/v1/collectionPoints")
        ? computed(() => mockPath("Stores", "collectionPoints", ``))
        : computed(() => `/stores/v1/collectionPoints`),
      method: "POST",
      body: data,
      key: "/stores/v1/collectionPoints",
      apiName: "Stores.collectionPoints",
      ...params,
    });
  /**
   * No description
   *
   * @tags Stores
   * @name CollectionPoints
   * @summary Collection Points
   * @request POST:/stores/v1/collectionPoints
   */
  $collectionPoints = (data: CollectionPointsPayload, requestParams: ApiOptions = {}) =>
    this.$request<CollectionPointsData>({
      path: isMocked("/stores/v1/collectionPoints")
        ? mockPath("Stores", "collectionPoints", ``)
        : `/stores/v1/collectionPoints`,
      method: "POST",
      body: data,
      apiName: "Stores.collectionPoints",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Stores
   * @name Search
   * @summary Search stores
   * @request POST:/stores/v1/search
   */
  search = <ResT = SearchData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    data: SearchPayload,
    query?: {
      enhancedStoreSearch?: boolean;
      filterBOPISStores?: boolean;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/stores/v1/search")
        ? computed(() => mockPath("Stores", "search", ``))
        : computed(() => `/stores/v1/search`),
      method: "POST",
      query: query,
      body: data,
      key: "/stores/v1/search",
      apiName: "Stores.search",
      ...params,
    });
  /**
   * No description
   *
   * @tags Stores
   * @name Search
   * @summary Search stores
   * @request POST:/stores/v1/search
   */
  $search = (
    data: SearchPayload,
    query?: {
      enhancedStoreSearch?: boolean;
      filterBOPISStores?: boolean;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<SearchData>({
      path: isMocked("/stores/v1/search") ? mockPath("Stores", "search", ``) : `/stores/v1/search`,
      method: "POST",
      query: query,
      body: data,
      apiName: "Stores.search",
      ...requestParams,
    });
}
