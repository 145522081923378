<template>
  <div ref="elementRef" class="relative grid h-full" @click="handlePromotionClick">
    <base-picture
      v-if="responsiveMedia.images"
      v-bind="responsiveMedia.images"
      :lazy
      fit="cover"
      class="full grid-area-stack"
      :props-img="{ class: 'h-full' }"
    />
    <base-video
      v-else-if="responsiveMedia.video"
      v-bind="responsiveMedia.video"
      ref="videoRef"
      class="full grid-area-stack"
      fit="cover"
      :autoplay="!lazy"
      :controls="false"
      :meta="responsiveMedia.video.sm"
      loop
    />
    <div :class="variantContentClass[variant] || variantContentClass.default" class="relative grid-area-stack">
      <div class="h-full f-col" :class="alignment[contentAlignment]">
        <h2
          v-if="title"
          v-style:c="titleColor"
          :class="titleStyle"
          class="mb-1"
          data-test-id="cms-banner-title"
        >
          {{ title }}
        </h2>
        <p
          v-if="subtitle"
          v-style:c="subtitleColor"
          :class="subtitleStyle"
          data-test-id="cms-banner-text"
        >
          {{ subtitle }}
        </p>
        <div
          v-if="targets.length"
          class="f-col gap-4 md:flex-row md:wrap not-first:pt-4"
        >
          <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" :size />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { BannerEspotAlignment, BannerEspotContent } from '#types/components/cms/banner-espot'
import type { ButtonSize } from '#types/components/vf/button'

const { content } = defineProps<{
  content: BannerEspotContent
}>()

const {
  contentAlignment,
  media,
  name,
  promotionTracking,
  subtitle,
  subtitleColor,
  subtitleStyle,
  targets = [],
  title,
  titleColor,
  titleStyle,
  variant
} = content

const { getMedia } = useCms()
const { elementRef, handlePromotionClick } = usePromotionTracking(promotionTracking, name)

const lazy = inject(LazyMedia)
const videoRef = ref()
const responsiveMedia = getMedia(media)
const size = { sm: { size: 'tiny' as ButtonSize } }

const variantContentClass = {
  'default': 'p-2 md:p-4',
  'full-width': 'p-6 md:p-8'
}

const alignment: Partial<Record<BannerEspotAlignment, string>> = {
  bottom: 'text-center justify-end items-center',
  center: 'text-center center',
  left: 'justify-center',
  top: 'text-center items-center'
}

onMounted(() => videoRef.value?.play())
</script>
