<template>
  <table data-test-id="vf-table" :class="brandClasses.table">
    <caption v-if="caption" :class="classCaption">
      {{ caption }}
    </caption>
    <thead
      data-test-id="vf-table-header"
      :class="{
        'sticky top-0 z-2': stuckY,
      }"
    >
      <slot name="head">
        <tr v-if="items?.length">
          <th
            v-for="({ key, label }, i) in fields"
            :key
            style="text-align: inherit"
            class="ws-nowrap"
            :class="[brandClasses.header, brandClasses.row, brandClasses.th, {
              [stuckXThClass]: !i && stuckX,
              'z-2': !i && stuckY && stuckX,
            }]"
            data-test-id="vf-table-header-value"
          >
            <span
              v-if="!i && stuckX && stuckBorder"
              class="absolute inset-y-0 right-0 -mr-px"
              :class="brandClasses.thXStuck"
            />
            {{ label }}
          </th>
        </tr>
      </slot>
      <span v-if="stuckBorder && stuckY" class="absolute inset-x-0 bottom-0 z-2" :class="brandClasses.thYStuck" />
    </thead>
    <tbody data-test-id="vf-table-body">
      <slot>
        <template v-if="items?.length">
          <tr v-for="(item, i) in items" :key="i">
            <td
              v-for="({ key }, j) in fields"
              :key
              class="ws-nowrap"
              :class="[brandClasses.row, brandClasses.td, {
                'bg-grey-90': stripedRows && i % 2,
                [stuckXTdClass]: !j && stuckX,
              }]"
              data-test-id="vf-table-body-value"
            >
              <span
                v-if="!j && stuckX && stuckBorder"
                class="absolute inset-y-0 right-0 -mr-px"
                :class="brandClasses.tdStuck"
              />
              {{ item[key] }}
            </td>
          </tr>
        </template>
      </slot>
    </tbody>
  </table>
</template>

<script lang="ts" setup>
const props = defineProps<{
  /**
   * Array of objects (think about each object as a table row)
   * Object shold contain pairs of key-value
   * Where key will be used as column name and value as value in appropriate column
   * of each row
   */
  items?: Record<string, any>[]
  /**
   * Array of configs to change column order or/and names
   */
  fields?: { key: string, label: string }[]
  /**
   * Stuck first column if `true`
   */
  stuckX?: boolean
  /**
   * Stuck first row if `true`
   */
  stuckY?: boolean
  /**
   * Adds border on stuck rows and columns
   */
  stuckBorder?: boolean
  /**
   * Adds background on even table rows
   */
  stripedRows?: boolean
  /**
   * Add description to table
   */
  caption?: string
  /**
   * Adds class to table caption
   */
  classCaption?: string
}>()

const { brandClasses } = useAppConfig().components.vf.table

const stuckXThClass = `sticky left-0 z-1 ${brandClasses.thSticky}`
const stuckXTdClass = `sticky left-0 z-1 bg-white ${brandClasses.tdSticky}`

const fields = computed(() => {
  if (props.fields) return props.fields
  if (props.items?.length) return Object.keys(props.items[0]).map((key) => ({ key, label: key }))
})
</script>
