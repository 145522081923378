<template>
  <div :data-test-id="`cms-${section.name}`" :data-cm-metadata="placementMetadata">
    <component
      :is="component.hydration ? LazyHydrate : 'div'"
      v-for="component in components"
      :key="component.id"
      :when="component.hydration"
    >
      <NuxtErrorBoundary @error="logError($event as Error, component)">
        <div
          v-style="{
            display: getVisibilityStyles(component.hideOnBreakpoint),
            mb: hasMargin(component),
            top: component.isSticky ? {
              sm: pxToRem(header.sticky.margin),
              md: pxToRem(header.sticky.margin),
            } : {},
          }"
          :class="[{
            'container': hasContainer(component),
            'sticky z-3': component.isSticky,
          }, classWrapper]"
          :data-test-id="`cms-${component.component}-${component.id}`"
        >
          <component
            :is="`lazy-cms-${component.component}`"
            :content="component"
            :data-cm-metadata="componentMetadata(component.id)"
          />
        </div>
      </NuxtErrorBoundary>
    </component>
  </div>
</template>

<script lang="ts" setup>
import { SectionContextKey } from './context'
import mappings from '#content/mappings'
import LazyHydrate from '#core/components/base/lazy-hydrate/LazyHydrate.vue'
import type { Content } from '#types/content'
import type { HydrationType } from '#types/mappings'
import type { Section } from '#types/page'
import type { Responsive } from '#types/common'

type ContentItem = {
  component: string
  hydration: HydrationType
  isSticky: boolean
  bottomMargins: Record<keyof Responsive, string>
} & Content

const { section, pageContext, lazyMedia } = defineProps<{
  /**
   * CMS Section with content components
   */
  section: Section | any
  /**
   * Use page context in case you need CMS controlled layout to be applied (container) and bottom spacing
   */
  pageContext?: boolean
  /**
   * Lazy load media objects like images and videos inside section
   * Use for elements that would not be visible on the first screen
   */
  lazyMedia?: boolean
  classWrapper?: string
}>()

provide(LazyMedia, section.lazy || lazyMedia)
provide(SectionContextKey, !Array.isArray(section) ? section : undefined)

const { isPreview } = useCms()
const { ds, components: { cms } } = useAppConfig()
const { monetateConfig } = useFeatureFlags()
const header = useHeaderStore()

const { componentSpacingMap, componentsWithoutContainer, componentsWithoutMargin } = cms.section
const { breakpoints } = ds

const components: ContentItem[] = (section.items || []).reduce((acc, item) => {
  if (!monetateConfig?.isMonetateActive && item.type === 'P13NExperience') return acc

  const config = resolveContentComponent(item, mappings)

  if (!config) return acc

  return [
    ...acc,
    {
      ...config,
      bottomMargins: Object.keys(breakpoints).reduce((acc, bp) => ({
        ...acc,
        [bp]: componentSpacingMap[item.bottomMargins?.[bp] || 'default']
      }), {})
    }
  ]
}, [] as ContentItem[])

const logError = (e: Error, { component, id }: ContentItem) => {
  log.error(`${e?.message} | CMS Error: component - ${component}, id - ${id}`, { stackTrace: e.stack })
}

const placementMetadata = isPreview
  ? JSON.stringify([
    { _: `properties.placement-${section.name}` },
    { placementRequest: [{ isInLayout: true, hasItems: true, placementName: section.name }] }
  ])
  : undefined

const componentMetadata = (id: string) => isPreview ? `[{"_":{"$Ref":"content/${id}"}}]` : undefined

const hasContainer = ({ isFullWidth, name, type }: ContentItem): boolean =>
  pageContext
  && !componentsWithoutContainer.includes(type)
  && !name?.includes('[fullwidth]')
  && !isFullWidth

const hasMargin = ({ type, bottomMargins }: ContentItem) =>
  !componentsWithoutMargin.includes(type)
  && (pageContext || components.length > 1)
    ? bottomMargins
    : null
</script>
