<template>
  <template v-if="!overrideDefault">
    <template v-if="type === 'panel'">
      <div
        :class="_position === 'bottom' ? 'flex' : 'relative h-full f-col'"
        :data-test-id="dataTestId || 'dialog-wrapper'"
        data-id="dialog-wrapper"
        @keydown.esc="$emit('resolve')"
      >
        <base-dialog-close
          v-if="!hideCloseButton"
          class="absolute right-0 top-0 z-1 my-3"
          :class="classClose"
          :aria-label="$t.close"
          data-test-id="vf-panel-close"
        >
          <vf-icon name="close" size="md" />
        </base-dialog-close>
        <base-dialog-title v-if="$slots.title" class="b-b b-b-grey-70 py-3 title-3 ">
          <slot name="title" />
        </base-dialog-title>
        <div v-bind="$attrs">
          <slot />
        </div>
      </div>
    </template>
    <template v-else>
      <div data-id="dialog-wrapper" @keydown.esc="$emit('resolve')">
        <base-dialog-close
          v-if="!hideCloseButton"
          :aria-label="$t.close"
          class="absolute right-0 top-0 z-1 mr-4 mt-4"
          data-test-id="vf-modal-close"
        >
          <slot name="close">
            <vf-icon name="close" size="md" />
          </slot>
        </base-dialog-close>
        <base-dialog-title v-if="$slots.title" class="subtitle-1">
          <slot name="title" />
        </base-dialog-title>
        <div v-bind="$attrs">
          <slot />
        </div>
      </div>
    </template>
  </template>
  <div v-else :data-test-id="dataTestId || 'dialog-wrapper'" data-id="dialog-wrapper">
    <slot />
  </div>
</template>

<script setup lang="ts">
import type { CSSClass } from '#types/common'
import type { DialogBaseOptions } from '#types/config/components/dialog'

const { position } = defineProps<{
  type?: 'panel' | 'modal'
  /**
   * Position of the panel.
   */
  position?: DialogBaseOptions['position']
  /**
   * Classes to be applied to close button.
   */
  classClose?: CSSClass
  /**
   * Whether "close" button is shown.
   */
  hideCloseButton?: boolean
  dataTestId?: string
  /**
   * Overrides default layout
   */
  overrideDefault?: boolean
}>()

defineEmits<{
  resolve: []
}>()

defineSlots<{
  default?: () => any
  title?: () => any
}>()

const { $viewport } = useNuxtApp()

const _position = isObject(position) ? getValueForBreakpoint($viewport.breakpoint, position)! : position
</script>
