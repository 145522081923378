<template>
  <slot :is-stuck="!!isStuck" />
</template>

<script lang="ts" setup>
import type { Position } from '#types/components/base/sticky'

const props = withDefaults(defineProps<{
  /**
   * A unique identifier for the sticky element.
   * Used to track its state in the `useSticky` state manager.
   */
  name: string
  /**
   * Determines the position where the element should stick.
   * Possible values: 'top', 'bottom', 'left', 'right'.
   * @default 'top'
   */
  position?: Position
  /**
   * Defines the margin (in pixels) from the sticky edge
   * to trigger the sticky behavior.
   * @default 0
   */
  margin?: number

  /**
   * If true, the sticky behavior activates when the element
   * scrolls above another target element.
   * @default false
   */
  stickAboveElement?: boolean
}>(), {
  position: 'top',
  margin: 0
})
defineSlots<{
  default: (props: { isStuck: boolean }) => void
}>()
const instance = getCurrentInstance()
const stickyState = useSticky()
const isStuck = computed({
  get() {
    return stickyState.value[props.name]
  },
  set(value: HTMLElement | null) {
    stickyState.value[props.name] = value
  }
})
let stop = () => {}
const isActive = ref(false)

const oppositePosition = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left'
}
const init = () => {
  isActive.value = true
  stop()
  const el = ['#text', '#comment'].includes(instance?.vnode.el?.nodeName) ? instance?.vnode.el?.nextElementSibling : instance?.vnode.el
  if (!el) return
  const scrollParent = getScrollParent(el)
  const root = scrollParent instanceof Window ? undefined : scrollParent

  ;({ stop } = useIntersectionObserver(
    el,
    ([{ isIntersecting }]) => (isStuck.value = !isIntersecting || props.stickAboveElement ? el : null),
    {
      threshold: 1,
      rootMargin: (['top', 'right', 'bottom', 'left'] as Position[])
        .map((position) => `${props.position === position
          ? -(props.margin + 1)
          : oppositePosition[props.position] === position ? 9999 : 0}px`)
        .join(' '),
      root
    }
  ))
}

watch(
  () => props.margin,
  () => { isActive.value && init() },
  { immediate: true }
)

const cleanup = () => {
  stop()
  isStuck.value = null
  isActive.value = false
}

// we need both mounted and activated as we do not know if component is used in keep alive parent or not
// also both will not be called in the same time in our app as we have keep alive on async pages
// and this such nuxt issue is not solved https://github.com/nuxt/nuxt/issues/26010
onMounted(init)
onActivated(init)
onUnmounted(cleanup)
onDeactivated(cleanup)
</script>
