import { getLocaleFromDomain } from '#brands/utils/i18n'
/**
 * Retrieves LaunchDarkly context based on headers and default locale.
 * @param headers HTTP headers containing context information.
 * @returns LaunchDarkly context object.
 * @category Utils
 */
export const getLDContext = (headers?, userId?) => {
  const {
    brand,
    isNetlify,
    targetEnv,
    disableProxyForNetlify,
    i18n: {
      defaultLocale
    }
  } = useRuntimeConfig().public
  const { locale, 'x-ld-passthrough': ldPassthroughHeader = '', host } = headers || {}
  return {
    key: userId ?? `${brand}_${locale}`,
    custom: {
      brand,
      disableProxyForNetlify,
      ldPassthroughHeader,
      locale: locale || getLocaleFromDomain(host) || defaultLocale,
      targetEnv,
      isNetlify
    }
  }
}
