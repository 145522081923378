<template>
  <div v-style:bg="backgroundColor" :class="{ 'py-6': backgroundColor }">
    <div :class="{ container: !isFullWidth }">
      <div v-style:m="containerMargin">
        <h2
          v-if="title"
          v-style:c="titleColor"
          :class="[
            titleStyle,
            titleStyleClasses, {
              'mx-4': isFullWidth,
              'text-center': alignment === 'center' || titleAlignment === 'center' || titlePosition === 'center',
            }]"
        >
          {{ title }}
        </h2>
        <vf-carousel-elevate
          ref="carousel"
          v-bind="carouselSettings"
          :style-container="{
            '--p': mobileSlidePadding,
            '--p-md': 0,
            '--gap': slideMargin.sm,
            '--gap-md': slideMargin.md,
            '--gap-lg': slideMargin.lg,
          }"
          :class-container="classContainer"
          :class-controls="classControls"
          :class="[collectionCenter, carouselClasses]"
        >
          <template #default="{ paused }">
            <div
              v-for="(item, i) in items"
              :key="item.id"
              v-style="{ 'max-w': slideMaxWidth, 'py': slidePadding, 'w': slideWidth }"
              :class="{ 'ml-0': i === 0 }"
              :interval="
                item.media?.[$viewport.breakpoint]?.duration
                  ? item.media?.[$viewport.breakpoint]?.duration * 1000
                  : undefined
              "
            >
              <component
                :is="`lazy-cms-${resolveContentComponent(item, mappings)?.component}`"
                :content="item"
                :paused
                is-part-of-collection
                :class="[itemClass, { 'children:h-inherit': isFullHeight }]"
                :style="{ height: isFullHeight
                  ? `calc(100svh - ${pxToRem(header.height[header.transparent ? 'promoBar' : 'full'])})` : undefined }"
              />
            </div>
          </template>
          <template #pagination="{ activeItem }">
            <div
              v-if="carouselSettings.showPagination && items.length"
              class="flex justify-center pt-6 space-x-2 "
            >
              <base-button
                v-for="(_, i) in items"
                :key="i"
                :aria-label="replaceAll($t.goToSlide, { slide: i + 1 })"
                :aria-current="activeItem === i"
                :class="activeItem === i ? 'b-grey-10 bg-grey-10' : 'b-grey-40 '"
                class="h-2 w-2 b rounded-full "
                @click="carousel.scrollTo(i)"
              />
            </div>
          </template>
        </vf-carousel-elevate>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import componentTypeConfig from './config'
import mappings from '#content/mappings'
import { resolveContentComponent } from '#content/utils'
import type { Responsive } from '#types/common'
import type { CollectionContent } from '#types/components/cms/collection'

const { content } = defineProps<{ content: CollectionContent<any> }>()

const { breakpoints } = useAppConfig().ds
const { classControls: defaultClassControls, showArrowsOnlyOnHover, sizeControls, spacing } = useAppConfig().components.cms.collection

const header = useHeaderStore()
const { $viewport } = useNuxtApp()

const carousel = ref()

const {
  alignment,
  backgroundColor,
  equalTargetSize,
  isFullHeight,
  isFullWidth,
  title,
  titleAlignment,
  titleColor,
  titlePosition,
  titleStyle
} = content
const { lg, md, sm } = content.breakpoints

const { carouselClasses, itemClass, itemsProp, titleStyleClasses } = componentTypeConfig[content.type] || {}
const items = (itemsProp ? content[itemsProp] : content.items) || []

const collectionCenter = alignment === 'center' && {
  'lg:justify-center lg:text-center lg:flex': lg?.type === 'carousel',
  '~md:justify-center ~md:text-center ~md:flex': md?.type === 'carousel',
  '<md:justify-center <md:text-center <md:flex': sm?.type === 'carousel'
}

const getItemMaxWidth = (maxW?: string) => maxW || 'none'

const slideWidth: Responsive = Object.keys(breakpoints).reduce((acc, cur) => {
  if (getValueForBreakpoint(cur as keyof Responsive, equalTargetSize)
    && content.type === 'VfCanvasCTAButtonCollection' && content.breakpoints[cur]?.type !== 'grid')
    return { ...acc, [cur]: '12.5rem' }

  const { slideWidth, slidePadding } = content.breakpoints[cur]

  if (!slideWidth)
    return { ...acc, [cur]: 'auto' }

  const width = slideWidth === 'auto' ? `${Math.round((100 / items.length) * 100) / 100}%` : slideWidth

  if (width === '100%')
    return { ...acc, [cur]: width }

  const itemsPerLine = content.breakpoints[cur]?.type === 'grid'
    ? Math.round(100 / Number.parseFloat(width))
    : items.length

  const margin = Math.ceil((((itemsPerLine - 1) * spacing[slidePadding || 'small']) / itemsPerLine) * 100) / 100

  return {
    ...acc,
    [cur]: `calc(${width} - ${Math.round((margin * 2) * 100) / 100}rem)`
  }
}, {} as Responsive)

const slideMaxWidth: Record<keyof Responsive, string> = {
  sm: getItemMaxWidth(sm?.slideMaxWidth),
  md: getItemMaxWidth(md?.slideMaxWidth),
  lg: getItemMaxWidth(lg?.slideMaxWidth)
}

const calculateSpacings = (mapper: (value: number) => number) => {
  return Object.keys(breakpoints).reduce((acc, cur) => {
    const { slidePadding } = content.breakpoints[cur]

    return {
      ...acc,
      [cur]: `${mapper(spacing[slidePadding] || 0)}rem`
    }
  }, {} as Responsive)
}

const slidePadding = calculateSpacings((value) => value)

const slideMargin = calculateSpacings((value) => value * 2)

// Mobile carousel centring
const mobileSlideWidth = Number.parseInt(content.breakpoints.sm.slideWidth!)
const mobileSlidePadding = Number.isNaN(mobileSlideWidth) ? 0 : `0 0 0 ${(100 - mobileSlideWidth) / 2}%`

const containerMargin: Record<keyof Responsive, any> = {
  sm: sm?.margin && `0 ${sm.margin}`,
  md: md?.margin && `0 ${md.margin}`,
  lg: lg?.margin && `0 ${lg.margin}`
}

const classControls = ((): string[] => {
  const hiddenClass = {
    sm: '<md:hidden',
    md: '~md:hidden',
    lg: 'lg:hidden'
  }

  return Object.keys(hiddenClass).reduce((acc, bp) => {
    if (content.breakpoints[bp]?.type === 'grid') return [...acc, hiddenClass[bp]]
    // show arrows by default if showArrows is not set
    return [...acc, ...(content.breakpoints[bp]?.showArrows ?? true) ? [] : [hiddenClass[bp]]]
  }, [defaultClassControls])
})()

/**
 * Get style classes for carousel containers
 */
const classContainer = [
  // Mobile slides centring
  'scroll-pa-$p',
  'md:scroll-pa-$p-md',
  'gap-x-$gap',
  'md:gap-x-$gap-md',
  'lg:gap-x-$gap-lg',
  // Carousel vs Grid Layout
  {
    '<md:wrap': sm?.type === 'grid',
    '~md:wrap': md?.type === 'grid',
    'lg:wrap': lg?.type === 'grid'
  }
]

const carouselSettings = computed(() => {
  const {
    autoplay,
    autorotateTime = 0,
    loop,
    showArrows,
    showIndicators,
    showProgress
  } = content.breakpoints[$viewport.breakpoint] || {}

  return {
    autoplay,
    dragFree: content.type === 'VfCanvasCTAButtonCollection',
    highlightCenter: content.variant === 'highlightCenter',
    interval: autorotateTime * 1000,
    loop,
    sizeControls,
    showArrows,
    showArrowsOnlyOnHover,
    showPagination: showIndicators,
    showProgress,
  }
})
</script>
