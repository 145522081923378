import componentsCart from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/cart"
import componentsCheckoutAddShippingAddress from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/checkout/addShippingAddress"
import componentsCheckoutCartContainer from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/checkout/cartContainer"
import componentsCheckoutShippingAndDelivery from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/checkout/shippingAndDelivery"
import componentsDialogQuickshop from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/dialog/quickshop"
import componentsDialogSignUp from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/dialog/signUp"
import componentsFormInterests from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/form/interests"
import componentsFormSignUp from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/form/signUp"
import componentsLoyaltyBenefits from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/loyalty/benefits"
import pagesLoyaltyProgramme from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/pages/loyaltyProgramme"
import pagesOrderStatus from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/pages/orderStatus"
import pagesRepairService from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/pages/repairService"
    
export default {"components":{"cart":componentsCart,"checkout":{"addShippingAddress":componentsCheckoutAddShippingAddress,"cartContainer":componentsCheckoutCartContainer,"shippingAndDelivery":componentsCheckoutShippingAndDelivery},"dialog":{"quickshop":componentsDialogQuickshop,"signUp":componentsDialogSignUp},"form":{"interests":componentsFormInterests,"signUp":componentsFormSignUp},"loyalty":{"benefits":componentsLoyaltyBenefits}},"pages":{"loyaltyProgramme":pagesLoyaltyProgramme,"orderStatus":pagesOrderStatus,"repairService":pagesRepairService}}
