<template>
  <base-progress
    v-slot="{ percentage }"
    :value
    :max
    class="relative"
    :style="{ width: `${pxToRem(+max * sizes[size])}` }"
    role="img"
    :aria-label="replaceAll($t.ratingLabel, { value, max })"
    data-test-id="vf-rating"
  >
    <vf-icon
      :name="`${icon}`"
      :size
      class="absolute c-grey-70"
      :style="{ ...maskStyles, width: '100%' }"
    />
    <vf-icon
      :name="`${icon}`"
      :size
            :style="{ width: `${percentage}%`, ...maskStyles }"
    />
  </base-progress>
</template>

<script lang="ts" setup>
import type { IconSizes } from '#types/sizes'

const props = withDefaults(
  defineProps<{
    /**
     * Custom icon name
     */
    icon?: string
    /**
     * Size of rating icon
     */
    size?: IconSizes
    /**
     * Defines maximum items in a rating (5, 10, etc.)
     */
    max?: number | string
    /**
     * Defines current rating, can be floating number or a string that will be converted to a number
     */
    value?: number | string
  }>(),
  {
    icon: 'star-filled',
    size: 'sm',
    max: 5,
    value: 0
  }
)

const { size: sizes } = useAppConfig().components.vf.icon

const maskStyles = computed(() => ({
  'mask-repeat': 'repeat',
  '-webkit-mask-repeat': 'repeat',
  'mask-size': `${pxToRem(sizes[props.size])} 100%`,
  '-webkit-mask-size': `${pxToRem(sizes[props.size])} 100%`
}))
</script>
