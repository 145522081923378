<template>
  <component :is="base ? BaseCollapse : VfAccordion" v-show="auth.loggedIn && details" v-model="opened" :class-toggle="classToggle">
    <template #toggle="{ expanded }">
      <div class="flex items-center py-3 between" data-test-id="vf-accordion-toggle">
        <div>
          <div class="text-md">
            {{ greeting }}
          </div>
          <div class="mt-1 truncate fw-bold">
            {{ replaceAll($t.loyaltyRewardsDetails.rewardAmount, { reward: currentReward }) }}
          </div>
        </div>
        <vf-icon name="chevron" size="lg" :dir="expanded ? 'up' : 'down'" />
      </div>
    </template>
    <template v-if="vouchers">
      <loyalty-rewards-details :vouchers />
      <loyalty-rewards-expiry v-if="vouchers.expiringLoyaltyPoints" :vouchers />
    </template>
  </component>
</template>

<script setup lang="ts">
import { BaseCollapse, VfAccordion } from '#components'

const props = withDefaults(defineProps<{ base?: boolean, defaultOpened?: boolean, classToggle?: string }>(), { base: false, defaultOpened: true })

const { $t } = useNuxtApp()
const { details } = storeToRefs(useProfileStore())
const auth = useAuthStore()
const { currentReward, get, vouchers } = useLoyaltyStore()

const greeting = computed(() =>
  details.value?.firstName
    ? replaceAll($t.loyaltyRewardsDetails.greetingWithName, { name: details.value.firstName })
    : $t.loyaltyRewardsDetails.greeting)

if (auth.loggedIn && !vouchers) get()

const opened = ref(props.defaultOpened)
</script>
