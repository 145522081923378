import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  brandClasses: {
    header: {
      wrapper: 'flex items-center between b-b b-grey-70 c-brand-1 fw-medium pb-3 pl-8',
      heading: 'text-lg',
      separator: 'b-l pl-1 b-brand-1',
      close: 'left-0 !my-0 right-unset'
    },
    footer: 'bg-white p-4 -m-4 shadow-top space-y-2',
    product: 'b-b b-grey-70 py-4 space-y-4',
    quantity: 'line-clamp-1 text-sm mb-2'
  }
} satisfies RecursivePartial<DialogConfig['miniCart']>
