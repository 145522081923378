import type { RecursivePartial } from '#types/utils'
import type { BenefitsConfig } from '#types/config/components/loyalty/benefits'

export default {
  benefits: [
    {
      icon: '/img/benefits/discount.svg',
      text: 'loyaltyBenefitsDiscount'
    },
    {
      icon: '/img/benefits/shipping.svg',
      text: 'loyaltyBenefitsFreeShipping'
    },
    {
      icon: '/img/benefits/gift.svg',
      text: 'loyaltyBenefitsBirthdayDiscount'
    },
    {
      icon: '/img/benefits/jacket.svg',
      text: 'loyaltyBenefitsGetAccess'
    },
    {
      icon: '/img/benefits/calendar.svg',
      text: 'loyaltyBenefitsGetAccess'
    },
    {
      icon: '/img/benefits/athlete.svg',
      text: 'loyaltyBenefitsExperiences'
    }
  ],
  iconWidth: 56,
  brandClasses: {
    heading: 'mb-4 title-3 uppercase',
  },
  showDiscountNote: true,
  showSubtitle: true
} satisfies RecursivePartial<BenefitsConfig>
