/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { KeysOf } from "#app/composables/asyncData";
import { isMocked, mockPath } from "#root/api/util/mocking";
import type { ApiOptions, UseApiOptions } from "../http-client";
import { HttpClient } from "../http-client";
import type {
  AddBillingAddressData,
  AddBillingAddressPayload,
  AddItemData,
  AddItemPayload,
  AddPaymentInstrumentData,
  AddPaymentInstrumentPayload,
  AddPriceAdjustmentData,
  AddPriceAdjustmentPayload,
  AddShippingAddressData,
  AddShippingAddressPayload,
  ApplyCouponData,
  ApplyCouponPayload,
  ApplyRewardData,
  ApplyRewardPayload,
  CreateData,
  CreatePayload,
  CreateWithItemData,
  CreateWithItemPayload,
  DeletePaymentInstrumentData,
  DeletePaymentInstrumentPayload,
  DeletePriceAdjustmentData,
  DeletePriceAdjustmentPayload,
  DeviceFingerPrintData,
  DeviceFingerPrintPayload,
  GetApplicableShippingMethodsData,
  GetData,
  GetGiftCardBalanceData,
  GetGiftCardBalancePayload,
  GetPaymentMethodsData,
  GetPaymentSessionData,
  PatchOrderData,
  PatchOrderPayload,
  PlaceOrderData,
  PlaceOrderPayload,
  PrepareOrderData,
  PrepareOrderPayload,
  RemoveBillingAddressData,
  RemoveCouponData,
  RemoveCouponPayload,
  RemoveItemData,
  RemoveItemPayload,
  RemoveSavedForLaterData,
  RemoveShippingAddressData,
  ReserveWithItemData,
  ReserveWithItemPayload,
  SaveForLaterData,
  SetShippingMethodData,
  SetShippingMethodPayload,
  UpdateItemData,
  UpdateItemPayload,
  UpdatePriceAdjustmentData,
  UpdatePriceAdjustmentPayload,
  UpdateSavedForLaterData,
  UpdateSavedForLaterPayload,
  ValidateShippingAddressData,
  ValidateShippingAddressPayload,
} from "./data-contracts";

export class Cart extends HttpClient {
  static paths = {
    create: "/cart/v1/cart",
    createWithItem: "/cart/v1/cartWithItem",
    get: "/cart/v1/cart/:basketId",
    addItem: "/cart/v1/item",
    removeItem: "/cart/v1/item",
    applyCoupon: "/cart/v1/coupon",
    removeCoupon: "/cart/v1/coupon",
    setShippingMethod: "/cart/v1/shippingMethod",
    updateItem: "/cart/v1/cart/:basketId/item",
    addShippingAddress: "/cart/v2/cart/:basketId/shippingAddress",
    removeShippingAddress: "/cart/v2/cart/:basketId/shippingAddress",
    validateShippingAddress: "/cart/v1/cart/:basketId/shippingAddress/validation",
    addBillingAddress: "/cart/v1/cart/:basketId/billingAddress",
    removeBillingAddress: "/cart/v1/cart/:basketId/billingAddress",
    getPaymentMethods: "/cart/v1/cart/:basketId/paymentMethods",
    getApplicableShippingMethods: "/cart/v1/cart/:basketId/shippingMethod/:shippingId",
    addPaymentInstrument: "/cart/v1/cart/:basketId/paymentInstruments",
    deletePaymentInstrument: "/cart/v1/cart/:basketId/paymentInstruments",
    applyReward: "/cart/v1/cart/:basketId/reward",
    updateSavedForLater: "/cart/v2/saveForLater",
    saveForLater: "/cart/v2/cart/:basketId/saveForLater",
    removeSavedForLater: "/cart/v2/saveForLater/:saveForlaterId/items/:itemId",
    getGiftCardBalance: "/cart/v1/giftcard/balance",
    getPaymentSession: "/cart/v1/paymentSession",
    prepareOrder: "/cart/v1/cart/prepareOrder",
    placeOrder: "/cart/v2/checkout/order",
    patchOrder: "/cart/v2/checkout/order/:orderNumber",
    deviceFingerPrint: "/cart/v1/deviceFingerPrint",
    reserveWithItem: "/cart/v1/reserveWithItem",
    addPriceAdjustment: "/cart/v1/cart/:basketId/price_adjustments",
    updatePriceAdjustment: "/cart/v1/cart/:basketId/price_adjustments/:adjustmentId",
    deletePriceAdjustment: "/cart/v1/cart/:basketId/price_adjustments/:adjustmentId",
  };
  static mockDataPaths = { get: "cart", getApplicableShippingMethods: "applicableShippingMethods" };
  static mockedPaths = {
    create: false,
    createWithItem: false,
    get: false,
    addItem: false,
    removeItem: false,
    applyCoupon: false,
    removeCoupon: false,
    setShippingMethod: false,
    updateItem: false,
    addShippingAddress: false,
    removeShippingAddress: false,
    validateShippingAddress: false,
    addBillingAddress: false,
    removeBillingAddress: false,
    getPaymentMethods: false,
    getApplicableShippingMethods: false,
    addPaymentInstrument: false,
    deletePaymentInstrument: false,
    applyReward: false,
    updateSavedForLater: false,
    saveForLater: false,
    removeSavedForLater: false,
    getGiftCardBalance: false,
    getPaymentSession: false,
    prepareOrder: false,
    placeOrder: false,
    patchOrder: false,
    deviceFingerPrint: false,
    reserveWithItem: false,
    addPriceAdjustment: false,
    updatePriceAdjustment: false,
    deletePriceAdjustment: false,
  };
  static onResponseHandlers = {};
  constructor(baseParams) {
    super(baseParams, "cart");
  }

  /**
   * No description
   *
   * @tags Cart
   * @name Create
   * @summary Create Empty Cart
   * @request POST:/cart/v1/cart
   */
  create = <ResT = CreateData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    data: CreatePayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/cart")
        ? computed(() => mockPath("Cart", "create", ``))
        : computed(() => `/cart/v1/cart`),
      method: "POST",
      body: data,
      key: "/cart/v1/cart",
      apiName: "Cart.create",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name Create
   * @summary Create Empty Cart
   * @request POST:/cart/v1/cart
   */
  $create = (data: CreatePayload, requestParams: ApiOptions = {}) =>
    this.$request<CreateData>({
      path: isMocked("/cart/v1/cart") ? mockPath("Cart", "create", ``) : `/cart/v1/cart`,
      method: "POST",
      body: data,
      apiName: "Cart.create",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name CreateWithItem
   * @summary Create Cart with Item
   * @request POST:/cart/v1/cartWithItem
   */
  createWithItem = <
    ResT = CreateWithItemData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: CreateWithItemPayload,
    query?: {
      /** basket id type (bid=cookie) */
      bid?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/cartWithItem")
        ? computed(() => mockPath("Cart", "createWithItem", ``))
        : computed(() => `/cart/v1/cartWithItem`),
      method: "POST",
      query: query,
      body: data,
      key: "/cart/v1/cartWithItem",
      apiName: "Cart.createWithItem",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name CreateWithItem
   * @summary Create Cart with Item
   * @request POST:/cart/v1/cartWithItem
   */
  $createWithItem = (
    data: CreateWithItemPayload,
    query?: {
      /** basket id type (bid=cookie) */
      bid?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<CreateWithItemData>({
      path: isMocked("/cart/v1/cartWithItem") ? mockPath("Cart", "createWithItem", ``) : `/cart/v1/cartWithItem`,
      method: "POST",
      query: query,
      body: data,
      apiName: "Cart.createWithItem",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name Get
   * @summary Get Cart
   * @request GET:/cart/v1/cart/{basketId}
   */
  get = <ResT = GetData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    basketId: MaybeRefOrGetter<string>,
    query?: {
      /** The pickup store's ID */
      favStoreId?: string;
      isPatchRequired?: boolean;
      inventorySupplyCheck?: boolean;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/cart/:basketId")
        ? computed(() => mockPath("Cart", "get", `basketId:${toValue(basketId)}`))
        : computed(() => `/cart/v1/cart/${toValue(basketId)}`),
      method: "GET",
      query: query,
      key: "/cart/v1/cart/:basketId",
      apiName: "Cart.get",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name Get
   * @summary Get Cart
   * @request GET:/cart/v1/cart/{basketId}
   */
  $get = (
    basketId: string,
    query?: {
      /** The pickup store's ID */
      favStoreId?: string;
      isPatchRequired?: boolean;
      inventorySupplyCheck?: boolean;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<GetData>({
      path: isMocked("/cart/v1/cart/:basketId")
        ? mockPath("Cart", "get", `basketId:${toValue(basketId)}`)
        : `/cart/v1/cart/${basketId}`,
      method: "GET",
      query: query,
      apiName: "Cart.get",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name AddItem
   * @summary Create Cart Item
   * @request POST:/cart/v1/item
   */
  addItem = <ResT = AddItemData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    data: AddItemPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/item")
        ? computed(() => mockPath("Cart", "addItem", ``))
        : computed(() => `/cart/v1/item`),
      method: "POST",
      body: data,
      key: "/cart/v1/item",
      apiName: "Cart.addItem",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name AddItem
   * @summary Create Cart Item
   * @request POST:/cart/v1/item
   */
  $addItem = (data: AddItemPayload, requestParams: ApiOptions = {}) =>
    this.$request<AddItemData>({
      path: isMocked("/cart/v1/item") ? mockPath("Cart", "addItem", ``) : `/cart/v1/item`,
      method: "POST",
      body: data,
      apiName: "Cart.addItem",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name RemoveItem
   * @summary Remove Cart  Item
   * @request DELETE:/cart/v1/item
   */
  removeItem = <ResT = RemoveItemData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    data: RemoveItemPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/item")
        ? computed(() => mockPath("Cart", "removeItem", ``))
        : computed(() => `/cart/v1/item`),
      method: "DELETE",
      body: data,
      key: "/cart/v1/item",
      apiName: "Cart.removeItem",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name RemoveItem
   * @summary Remove Cart  Item
   * @request DELETE:/cart/v1/item
   */
  $removeItem = (data: RemoveItemPayload, requestParams: ApiOptions = {}) =>
    this.$request<RemoveItemData>({
      path: isMocked("/cart/v1/item") ? mockPath("Cart", "removeItem", ``) : `/cart/v1/item`,
      method: "DELETE",
      body: data,
      apiName: "Cart.removeItem",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name ApplyCoupon
   * @summary Apply Coupon
   * @request PUT:/cart/v1/coupon
   */
  applyCoupon = <ResT = ApplyCouponData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    data: ApplyCouponPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/coupon")
        ? computed(() => mockPath("Cart", "applyCoupon", ``))
        : computed(() => `/cart/v1/coupon`),
      method: "PUT",
      body: data,
      key: "/cart/v1/coupon",
      apiName: "Cart.applyCoupon",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name ApplyCoupon
   * @summary Apply Coupon
   * @request PUT:/cart/v1/coupon
   */
  $applyCoupon = (data: ApplyCouponPayload, requestParams: ApiOptions = {}) =>
    this.$request<ApplyCouponData>({
      path: isMocked("/cart/v1/coupon") ? mockPath("Cart", "applyCoupon", ``) : `/cart/v1/coupon`,
      method: "PUT",
      body: data,
      apiName: "Cart.applyCoupon",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name RemoveCoupon
   * @summary Remove Coupon
   * @request DELETE:/cart/v1/coupon
   */
  removeCoupon = <
    ResT = RemoveCouponData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: RemoveCouponPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/coupon")
        ? computed(() => mockPath("Cart", "removeCoupon", ``))
        : computed(() => `/cart/v1/coupon`),
      method: "DELETE",
      body: data,
      key: "/cart/v1/coupon",
      apiName: "Cart.removeCoupon",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name RemoveCoupon
   * @summary Remove Coupon
   * @request DELETE:/cart/v1/coupon
   */
  $removeCoupon = (data: RemoveCouponPayload, requestParams: ApiOptions = {}) =>
    this.$request<RemoveCouponData>({
      path: isMocked("/cart/v1/coupon") ? mockPath("Cart", "removeCoupon", ``) : `/cart/v1/coupon`,
      method: "DELETE",
      body: data,
      apiName: "Cart.removeCoupon",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name SetShippingMethod
   * @summary Set Shipping Method
   * @request POST:/cart/v1/shippingMethod
   */
  setShippingMethod = <
    ResT = SetShippingMethodData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: SetShippingMethodPayload,
    query?: {
      /** basket id type (bid=cookie) */
      bid?: string;
      /** payment method context (context=APPLE_PAY_EXPRESS) */
      context?: "APPLE_PAY_EXPRESS" | "COLLECTION" | "PAYPAL_EXPRESS";
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/shippingMethod")
        ? computed(() => mockPath("Cart", "setShippingMethod", ``))
        : computed(() => `/cart/v1/shippingMethod`),
      method: "POST",
      query: query,
      body: data,
      key: "/cart/v1/shippingMethod",
      apiName: "Cart.setShippingMethod",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name SetShippingMethod
   * @summary Set Shipping Method
   * @request POST:/cart/v1/shippingMethod
   */
  $setShippingMethod = (
    data: SetShippingMethodPayload,
    query?: {
      /** basket id type (bid=cookie) */
      bid?: string;
      /** payment method context (context=APPLE_PAY_EXPRESS) */
      context?: "APPLE_PAY_EXPRESS" | "COLLECTION" | "PAYPAL_EXPRESS";
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<SetShippingMethodData>({
      path: isMocked("/cart/v1/shippingMethod") ? mockPath("Cart", "setShippingMethod", ``) : `/cart/v1/shippingMethod`,
      method: "POST",
      query: query,
      body: data,
      apiName: "Cart.setShippingMethod",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name UpdateItem
   * @summary Update Item in Cart
   * @request PUT:/cart/v1/cart/{basketId}/item
   */
  updateItem = <ResT = UpdateItemData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    basketId: MaybeRefOrGetter<string>,
    data: UpdateItemPayload,
    query?: {
      /** The delivery action type */
      action?: "pickup";
      /** The pickup store's ID */
      favStoreId?: string;
      /** A custom amount for a gift card item */
      amount?: number;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/cart/:basketId/item")
        ? computed(() => mockPath("Cart", "updateItem", `basketId:${toValue(basketId)}`))
        : computed(() => `/cart/v1/cart/${toValue(basketId)}/item`),
      method: "PUT",
      query: query,
      body: data,
      key: "/cart/v1/cart/:basketId/item",
      apiName: "Cart.updateItem",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name UpdateItem
   * @summary Update Item in Cart
   * @request PUT:/cart/v1/cart/{basketId}/item
   */
  $updateItem = (
    basketId: string,
    data: UpdateItemPayload,
    query?: {
      /** The delivery action type */
      action?: "pickup";
      /** The pickup store's ID */
      favStoreId?: string;
      /** A custom amount for a gift card item */
      amount?: number;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<UpdateItemData>({
      path: isMocked("/cart/v1/cart/:basketId/item")
        ? mockPath("Cart", "updateItem", `basketId:${toValue(basketId)}`)
        : `/cart/v1/cart/${basketId}/item`,
      method: "PUT",
      query: query,
      body: data,
      apiName: "Cart.updateItem",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name AddShippingAddress
   * @summary Add Shipping Address
   * @request POST:/cart/v2/cart/{basketId}/shippingAddress
   */
  addShippingAddress = <
    ResT = AddShippingAddressData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    basketId: MaybeRefOrGetter<string>,
    data: AddShippingAddressPayload,
    query?: {
      /** basket id type (bid=cookie) */
      bid?: string;
      /** payment method context (context=APPLE_PAY_EXPRESS) */
      context?: "APPLE_PAY_EXPRESS" | "COLLECTION" | "PAYPAL_EXPRESS" | "ROBIS";
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v2/cart/:basketId/shippingAddress")
        ? computed(() => mockPath("Cart", "addShippingAddress", `basketId:${toValue(basketId)}`))
        : computed(() => `/cart/v2/cart/${toValue(basketId)}/shippingAddress`),
      method: "POST",
      query: query,
      body: data,
      key: "/cart/v2/cart/:basketId/shippingAddress",
      apiName: "Cart.addShippingAddress",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name AddShippingAddress
   * @summary Add Shipping Address
   * @request POST:/cart/v2/cart/{basketId}/shippingAddress
   */
  $addShippingAddress = (
    basketId: string,
    data: AddShippingAddressPayload,
    query?: {
      /** basket id type (bid=cookie) */
      bid?: string;
      /** payment method context (context=APPLE_PAY_EXPRESS) */
      context?: "APPLE_PAY_EXPRESS" | "COLLECTION" | "PAYPAL_EXPRESS" | "ROBIS";
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<AddShippingAddressData>({
      path: isMocked("/cart/v2/cart/:basketId/shippingAddress")
        ? mockPath("Cart", "addShippingAddress", `basketId:${toValue(basketId)}`)
        : `/cart/v2/cart/${basketId}/shippingAddress`,
      method: "POST",
      query: query,
      body: data,
      apiName: "Cart.addShippingAddress",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name RemoveShippingAddress
   * @summary Remove Shipping Address
   * @request DELETE:/cart/v2/cart/{basketId}/shippingAddress
   */
  removeShippingAddress = <
    ResT = RemoveShippingAddressData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    basketId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v2/cart/:basketId/shippingAddress")
        ? computed(() => mockPath("Cart", "removeShippingAddress", `basketId:${toValue(basketId)}`))
        : computed(() => `/cart/v2/cart/${toValue(basketId)}/shippingAddress`),
      method: "DELETE",
      key: "/cart/v2/cart/:basketId/shippingAddress",
      apiName: "Cart.removeShippingAddress",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name RemoveShippingAddress
   * @summary Remove Shipping Address
   * @request DELETE:/cart/v2/cart/{basketId}/shippingAddress
   */
  $removeShippingAddress = (basketId: string, requestParams: ApiOptions = {}) =>
    this.$request<RemoveShippingAddressData>({
      path: isMocked("/cart/v2/cart/:basketId/shippingAddress")
        ? mockPath("Cart", "removeShippingAddress", `basketId:${toValue(basketId)}`)
        : `/cart/v2/cart/${basketId}/shippingAddress`,
      method: "DELETE",
      apiName: "Cart.removeShippingAddress",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name ValidateShippingAddress
   * @summary Validate Shipping Address
   * @request POST:/cart/v1/cart/{basketId}/shippingAddress/validation
   */
  validateShippingAddress = <
    ResT = ValidateShippingAddressData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    basketId: MaybeRefOrGetter<string>,
    data: ValidateShippingAddressPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/cart/:basketId/shippingAddress/validation")
        ? computed(() => mockPath("Cart", "validateShippingAddress", `basketId:${toValue(basketId)}`))
        : computed(() => `/cart/v1/cart/${toValue(basketId)}/shippingAddress/validation`),
      method: "POST",
      body: data,
      key: "/cart/v1/cart/:basketId/shippingAddress/validation",
      apiName: "Cart.validateShippingAddress",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name ValidateShippingAddress
   * @summary Validate Shipping Address
   * @request POST:/cart/v1/cart/{basketId}/shippingAddress/validation
   */
  $validateShippingAddress = (basketId: string, data: ValidateShippingAddressPayload, requestParams: ApiOptions = {}) =>
    this.$request<ValidateShippingAddressData>({
      path: isMocked("/cart/v1/cart/:basketId/shippingAddress/validation")
        ? mockPath("Cart", "validateShippingAddress", `basketId:${toValue(basketId)}`)
        : `/cart/v1/cart/${basketId}/shippingAddress/validation`,
      method: "POST",
      body: data,
      apiName: "Cart.validateShippingAddress",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name AddBillingAddress
   * @summary Add Billing Address
   * @request POST:/cart/v1/cart/{basketId}/billingAddress
   */
  addBillingAddress = <
    ResT = AddBillingAddressData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    basketId: MaybeRefOrGetter<string>,
    data: AddBillingAddressPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/cart/:basketId/billingAddress")
        ? computed(() => mockPath("Cart", "addBillingAddress", `basketId:${toValue(basketId)}`))
        : computed(() => `/cart/v1/cart/${toValue(basketId)}/billingAddress`),
      method: "POST",
      body: data,
      key: "/cart/v1/cart/:basketId/billingAddress",
      apiName: "Cart.addBillingAddress",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name AddBillingAddress
   * @summary Add Billing Address
   * @request POST:/cart/v1/cart/{basketId}/billingAddress
   */
  $addBillingAddress = (basketId: string, data: AddBillingAddressPayload, requestParams: ApiOptions = {}) =>
    this.$request<AddBillingAddressData>({
      path: isMocked("/cart/v1/cart/:basketId/billingAddress")
        ? mockPath("Cart", "addBillingAddress", `basketId:${toValue(basketId)}`)
        : `/cart/v1/cart/${basketId}/billingAddress`,
      method: "POST",
      body: data,
      apiName: "Cart.addBillingAddress",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name RemoveBillingAddress
   * @summary Remove Billing Address
   * @request DELETE:/cart/v1/cart/{basketId}/billingAddress
   */
  removeBillingAddress = <
    ResT = RemoveBillingAddressData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    basketId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/cart/:basketId/billingAddress")
        ? computed(() => mockPath("Cart", "removeBillingAddress", `basketId:${toValue(basketId)}`))
        : computed(() => `/cart/v1/cart/${toValue(basketId)}/billingAddress`),
      method: "DELETE",
      key: "/cart/v1/cart/:basketId/billingAddress",
      apiName: "Cart.removeBillingAddress",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name RemoveBillingAddress
   * @summary Remove Billing Address
   * @request DELETE:/cart/v1/cart/{basketId}/billingAddress
   */
  $removeBillingAddress = (basketId: string, requestParams: ApiOptions = {}) =>
    this.$request<RemoveBillingAddressData>({
      path: isMocked("/cart/v1/cart/:basketId/billingAddress")
        ? mockPath("Cart", "removeBillingAddress", `basketId:${toValue(basketId)}`)
        : `/cart/v1/cart/${basketId}/billingAddress`,
      method: "DELETE",
      apiName: "Cart.removeBillingAddress",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name GetPaymentMethods
   * @summary Get Applicable Payment Methods
   * @request GET:/cart/v1/cart/{basketId}/paymentMethods
   */
  getPaymentMethods = <
    ResT = GetPaymentMethodsData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    basketId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/cart/:basketId/paymentMethods")
        ? computed(() => mockPath("Cart", "getPaymentMethods", `basketId:${toValue(basketId)}`))
        : computed(() => `/cart/v1/cart/${toValue(basketId)}/paymentMethods`),
      method: "GET",
      key: "/cart/v1/cart/:basketId/paymentMethods",
      apiName: "Cart.getPaymentMethods",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name GetPaymentMethods
   * @summary Get Applicable Payment Methods
   * @request GET:/cart/v1/cart/{basketId}/paymentMethods
   */
  $getPaymentMethods = (basketId: string, requestParams: ApiOptions = {}) =>
    this.$request<GetPaymentMethodsData>({
      path: isMocked("/cart/v1/cart/:basketId/paymentMethods")
        ? mockPath("Cart", "getPaymentMethods", `basketId:${toValue(basketId)}`)
        : `/cart/v1/cart/${basketId}/paymentMethods`,
      method: "GET",
      apiName: "Cart.getPaymentMethods",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name GetApplicableShippingMethods
   * @summary Get Applicable Shipping Methods
   * @request GET:/cart/v1/cart/{basketId}/shippingMethod/{shippingId}
   */
  getApplicableShippingMethods = <
    ResT = GetApplicableShippingMethodsData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    basketId: MaybeRefOrGetter<string>,
    shippingId: MaybeRefOrGetter<"me" | "customs" | "digital" | "pickup1">,
    query?: {
      /** basket id type (bid=cookie) */
      bid?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/cart/:basketId/shippingMethod/:shippingId")
        ? computed(() =>
            mockPath(
              "Cart",
              "getApplicableShippingMethods",
              `basketId:${toValue(basketId)}.shippingId:${toValue(shippingId)}`,
            ),
          )
        : computed(() => `/cart/v1/cart/${toValue(basketId)}/shippingMethod/${toValue(shippingId)}`),
      method: "GET",
      query: query,
      key: "/cart/v1/cart/:basketId/shippingMethod/:shippingId",
      apiName: "Cart.getApplicableShippingMethods",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name GetApplicableShippingMethods
   * @summary Get Applicable Shipping Methods
   * @request GET:/cart/v1/cart/{basketId}/shippingMethod/{shippingId}
   */
  $getApplicableShippingMethods = (
    basketId: string,
    shippingId: "me" | "customs" | "digital" | "pickup1",
    query?: {
      /** basket id type (bid=cookie) */
      bid?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<GetApplicableShippingMethodsData>({
      path: isMocked("/cart/v1/cart/:basketId/shippingMethod/:shippingId")
        ? mockPath(
            "Cart",
            "getApplicableShippingMethods",
            `basketId:${toValue(basketId)}.shippingId:${toValue(shippingId)}`,
          )
        : `/cart/v1/cart/${basketId}/shippingMethod/${shippingId}`,
      method: "GET",
      query: query,
      apiName: "Cart.getApplicableShippingMethods",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name AddPaymentInstrument
   * @summary Add Payment Instrument
   * @request POST:/cart/v1/cart/{basketId}/paymentInstruments
   */
  addPaymentInstrument = <
    ResT = AddPaymentInstrumentData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    basketId: MaybeRefOrGetter<string>,
    data: AddPaymentInstrumentPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/cart/:basketId/paymentInstruments")
        ? computed(() => mockPath("Cart", "addPaymentInstrument", `basketId:${toValue(basketId)}`))
        : computed(() => `/cart/v1/cart/${toValue(basketId)}/paymentInstruments`),
      method: "POST",
      body: data,
      key: "/cart/v1/cart/:basketId/paymentInstruments",
      apiName: "Cart.addPaymentInstrument",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name AddPaymentInstrument
   * @summary Add Payment Instrument
   * @request POST:/cart/v1/cart/{basketId}/paymentInstruments
   */
  $addPaymentInstrument = (basketId: string, data: AddPaymentInstrumentPayload, requestParams: ApiOptions = {}) =>
    this.$request<AddPaymentInstrumentData>({
      path: isMocked("/cart/v1/cart/:basketId/paymentInstruments")
        ? mockPath("Cart", "addPaymentInstrument", `basketId:${toValue(basketId)}`)
        : `/cart/v1/cart/${basketId}/paymentInstruments`,
      method: "POST",
      body: data,
      apiName: "Cart.addPaymentInstrument",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name DeletePaymentInstrument
   * @summary Delete Payment Instrument
   * @request DELETE:/cart/v1/cart/{basketId}/paymentInstruments
   */
  deletePaymentInstrument = <
    ResT = DeletePaymentInstrumentData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    basketId: MaybeRefOrGetter<string>,
    data: DeletePaymentInstrumentPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/cart/:basketId/paymentInstruments")
        ? computed(() => mockPath("Cart", "deletePaymentInstrument", `basketId:${toValue(basketId)}`))
        : computed(() => `/cart/v1/cart/${toValue(basketId)}/paymentInstruments`),
      method: "DELETE",
      body: data,
      key: "/cart/v1/cart/:basketId/paymentInstruments",
      apiName: "Cart.deletePaymentInstrument",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name DeletePaymentInstrument
   * @summary Delete Payment Instrument
   * @request DELETE:/cart/v1/cart/{basketId}/paymentInstruments
   */
  $deletePaymentInstrument = (basketId: string, data: DeletePaymentInstrumentPayload, requestParams: ApiOptions = {}) =>
    this.$request<DeletePaymentInstrumentData>({
      path: isMocked("/cart/v1/cart/:basketId/paymentInstruments")
        ? mockPath("Cart", "deletePaymentInstrument", `basketId:${toValue(basketId)}`)
        : `/cart/v1/cart/${basketId}/paymentInstruments`,
      method: "DELETE",
      body: data,
      apiName: "Cart.deletePaymentInstrument",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name ApplyReward
   * @summary Apply Reward
   * @request PUT:/cart/v1/cart/{basketId}/reward
   */
  applyReward = <ResT = ApplyRewardData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    basketId: MaybeRefOrGetter<string>,
    data: ApplyRewardPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/cart/:basketId/reward")
        ? computed(() => mockPath("Cart", "applyReward", `basketId:${toValue(basketId)}`))
        : computed(() => `/cart/v1/cart/${toValue(basketId)}/reward`),
      method: "PUT",
      body: data,
      key: "/cart/v1/cart/:basketId/reward",
      apiName: "Cart.applyReward",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name ApplyReward
   * @summary Apply Reward
   * @request PUT:/cart/v1/cart/{basketId}/reward
   */
  $applyReward = (basketId: string, data: ApplyRewardPayload, requestParams: ApiOptions = {}) =>
    this.$request<ApplyRewardData>({
      path: isMocked("/cart/v1/cart/:basketId/reward")
        ? mockPath("Cart", "applyReward", `basketId:${toValue(basketId)}`)
        : `/cart/v1/cart/${basketId}/reward`,
      method: "PUT",
      body: data,
      apiName: "Cart.applyReward",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name UpdateSavedForLater
   * @summary Update Saved for Later
   * @request PUT:/cart/v2/saveForLater
   */
  updateSavedForLater = <
    ResT = UpdateSavedForLaterData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: UpdateSavedForLaterPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v2/saveForLater")
        ? computed(() => mockPath("Cart", "updateSavedForLater", ``))
        : computed(() => `/cart/v2/saveForLater`),
      method: "PUT",
      body: data,
      key: "/cart/v2/saveForLater",
      apiName: "Cart.updateSavedForLater",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name UpdateSavedForLater
   * @summary Update Saved for Later
   * @request PUT:/cart/v2/saveForLater
   */
  $updateSavedForLater = (data: UpdateSavedForLaterPayload, requestParams: ApiOptions = {}) =>
    this.$request<UpdateSavedForLaterData>({
      path: isMocked("/cart/v2/saveForLater") ? mockPath("Cart", "updateSavedForLater", ``) : `/cart/v2/saveForLater`,
      method: "PUT",
      body: data,
      apiName: "Cart.updateSavedForLater",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name SaveForLater
   * @summary Save for Later
   * @request POST:/cart/v2/cart/{basketId}/saveForLater
   */
  saveForLater = <
    ResT = SaveForLaterData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    basketId: MaybeRefOrGetter<string>,
    data: any,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v2/cart/:basketId/saveForLater")
        ? computed(() => mockPath("Cart", "saveForLater", `basketId:${toValue(basketId)}`))
        : computed(() => `/cart/v2/cart/${toValue(basketId)}/saveForLater`),
      method: "POST",
      body: data,
      key: "/cart/v2/cart/:basketId/saveForLater",
      apiName: "Cart.saveForLater",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name SaveForLater
   * @summary Save for Later
   * @request POST:/cart/v2/cart/{basketId}/saveForLater
   */
  $saveForLater = (basketId: string, data: any, requestParams: ApiOptions = {}) =>
    this.$request<SaveForLaterData>({
      path: isMocked("/cart/v2/cart/:basketId/saveForLater")
        ? mockPath("Cart", "saveForLater", `basketId:${toValue(basketId)}`)
        : `/cart/v2/cart/${basketId}/saveForLater`,
      method: "POST",
      body: data,
      apiName: "Cart.saveForLater",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name RemoveSavedForLater
   * @summary Remove Saved for Later
   * @request DELETE:/cart/v2/saveForLater/{saveForlaterId}/items/{itemId}
   */
  removeSavedForLater = <
    ResT = RemoveSavedForLaterData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    itemId: MaybeRefOrGetter<string>,
    saveForlaterId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v2/saveForLater/:saveForlaterId/items/:itemId")
        ? computed(() =>
            mockPath(
              "Cart",
              "removeSavedForLater",
              `saveForlaterId:${toValue(saveForlaterId)}.itemId:${toValue(itemId)}`,
            ),
          )
        : computed(() => `/cart/v2/saveForLater/${toValue(saveForlaterId)}/items/${toValue(itemId)}`),
      method: "DELETE",
      key: "/cart/v2/saveForLater/:saveForlaterId/items/:itemId",
      apiName: "Cart.removeSavedForLater",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name RemoveSavedForLater
   * @summary Remove Saved for Later
   * @request DELETE:/cart/v2/saveForLater/{saveForlaterId}/items/{itemId}
   */
  $removeSavedForLater = (itemId: string, saveForlaterId: string, requestParams: ApiOptions = {}) =>
    this.$request<RemoveSavedForLaterData>({
      path: isMocked("/cart/v2/saveForLater/:saveForlaterId/items/:itemId")
        ? mockPath("Cart", "removeSavedForLater", `saveForlaterId:${toValue(saveForlaterId)}.itemId:${toValue(itemId)}`)
        : `/cart/v2/saveForLater/${saveForlaterId}/items/${itemId}`,
      method: "DELETE",
      apiName: "Cart.removeSavedForLater",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name GetGiftCardBalance
   * @summary Get Gift Card Balance
   * @request POST:/cart/v1/giftcard/balance
   */
  getGiftCardBalance = <
    ResT = GetGiftCardBalanceData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: GetGiftCardBalancePayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/giftcard/balance")
        ? computed(() => mockPath("Cart", "getGiftCardBalance", ``))
        : computed(() => `/cart/v1/giftcard/balance`),
      method: "POST",
      body: data,
      key: "/cart/v1/giftcard/balance",
      apiName: "Cart.getGiftCardBalance",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name GetGiftCardBalance
   * @summary Get Gift Card Balance
   * @request POST:/cart/v1/giftcard/balance
   */
  $getGiftCardBalance = (data: GetGiftCardBalancePayload, requestParams: ApiOptions = {}) =>
    this.$request<GetGiftCardBalanceData>({
      path: isMocked("/cart/v1/giftcard/balance")
        ? mockPath("Cart", "getGiftCardBalance", ``)
        : `/cart/v1/giftcard/balance`,
      method: "POST",
      body: data,
      apiName: "Cart.getGiftCardBalance",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name GetPaymentSession
   * @summary Get Payment Session
   * @request GET:/cart/v1/paymentSession
   */
  getPaymentSession = <
    ResT = GetPaymentSessionData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    query: {
      referer: string;
      paymentMethodId: string;
      paymentMethod?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/paymentSession")
        ? computed(() => mockPath("Cart", "getPaymentSession", ``))
        : computed(() => `/cart/v1/paymentSession`),
      method: "GET",
      query: query,
      key: "/cart/v1/paymentSession",
      apiName: "Cart.getPaymentSession",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name GetPaymentSession
   * @summary Get Payment Session
   * @request GET:/cart/v1/paymentSession
   */
  $getPaymentSession = (
    query: {
      referer: string;
      paymentMethodId: string;
      paymentMethod?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<GetPaymentSessionData>({
      path: isMocked("/cart/v1/paymentSession") ? mockPath("Cart", "getPaymentSession", ``) : `/cart/v1/paymentSession`,
      method: "GET",
      query: query,
      apiName: "Cart.getPaymentSession",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name PrepareOrder
   * @summary Prepare Order
   * @request POST:/cart/v1/cart/prepareOrder
   */
  prepareOrder = <
    ResT = PrepareOrderData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: PrepareOrderPayload,
    query?: {
      /** basket id type (bid=cookie) */
      bid?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/cart/prepareOrder")
        ? computed(() => mockPath("Cart", "prepareOrder", ``))
        : computed(() => `/cart/v1/cart/prepareOrder`),
      method: "POST",
      query: query,
      body: data,
      key: "/cart/v1/cart/prepareOrder",
      apiName: "Cart.prepareOrder",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name PrepareOrder
   * @summary Prepare Order
   * @request POST:/cart/v1/cart/prepareOrder
   */
  $prepareOrder = (
    data: PrepareOrderPayload,
    query?: {
      /** basket id type (bid=cookie) */
      bid?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<PrepareOrderData>({
      path: isMocked("/cart/v1/cart/prepareOrder")
        ? mockPath("Cart", "prepareOrder", ``)
        : `/cart/v1/cart/prepareOrder`,
      method: "POST",
      query: query,
      body: data,
      apiName: "Cart.prepareOrder",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name PlaceOrder
   * @summary Place Order
   * @request PUT:/cart/v2/checkout/order
   */
  placeOrder = <ResT = PlaceOrderData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    data: PlaceOrderPayload,
    query?: {
      /** basket id type (bid=cookie) */
      bid?: string;
      /** payment method context (context=robis) */
      context?: "robis";
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v2/checkout/order")
        ? computed(() => mockPath("Cart", "placeOrder", ``))
        : computed(() => `/cart/v2/checkout/order`),
      method: "PUT",
      query: query,
      body: data,
      key: "/cart/v2/checkout/order",
      apiName: "Cart.placeOrder",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name PlaceOrder
   * @summary Place Order
   * @request PUT:/cart/v2/checkout/order
   */
  $placeOrder = (
    data: PlaceOrderPayload,
    query?: {
      /** basket id type (bid=cookie) */
      bid?: string;
      /** payment method context (context=robis) */
      context?: "robis";
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<PlaceOrderData>({
      path: isMocked("/cart/v2/checkout/order") ? mockPath("Cart", "placeOrder", ``) : `/cart/v2/checkout/order`,
      method: "PUT",
      query: query,
      body: data,
      apiName: "Cart.placeOrder",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name PatchOrder
   * @summary Patch Order
   * @request PATCH:/cart/v2/checkout/order/{orderNumber}
   */
  patchOrder = <ResT = PatchOrderData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    orderNumber: MaybeRefOrGetter<string>,
    data: PatchOrderPayload,
    query?: {
      /** The response from CAPTCHA */
      captchaResponse?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v2/checkout/order/:orderNumber")
        ? computed(() => mockPath("Cart", "patchOrder", `orderNumber:${toValue(orderNumber)}`))
        : computed(() => `/cart/v2/checkout/order/${toValue(orderNumber)}`),
      method: "PATCH",
      query: query,
      body: data,
      key: "/cart/v2/checkout/order/:orderNumber",
      apiName: "Cart.patchOrder",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name PatchOrder
   * @summary Patch Order
   * @request PATCH:/cart/v2/checkout/order/{orderNumber}
   */
  $patchOrder = (
    orderNumber: string,
    data: PatchOrderPayload,
    query?: {
      /** The response from CAPTCHA */
      captchaResponse?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<PatchOrderData>({
      path: isMocked("/cart/v2/checkout/order/:orderNumber")
        ? mockPath("Cart", "patchOrder", `orderNumber:${toValue(orderNumber)}`)
        : `/cart/v2/checkout/order/${orderNumber}`,
      method: "PATCH",
      query: query,
      body: data,
      apiName: "Cart.patchOrder",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name DeviceFingerPrint
   * @summary Device Fingerprint
   * @request POST:/cart/v1/deviceFingerPrint
   */
  deviceFingerPrint = <
    ResT = DeviceFingerPrintData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: DeviceFingerPrintPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/deviceFingerPrint")
        ? computed(() => mockPath("Cart", "deviceFingerPrint", ``))
        : computed(() => `/cart/v1/deviceFingerPrint`),
      method: "POST",
      body: data,
      key: "/cart/v1/deviceFingerPrint",
      apiName: "Cart.deviceFingerPrint",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name DeviceFingerPrint
   * @summary Device Fingerprint
   * @request POST:/cart/v1/deviceFingerPrint
   */
  $deviceFingerPrint = (data: DeviceFingerPrintPayload, requestParams: ApiOptions = {}) =>
    this.$request<DeviceFingerPrintData>({
      path: isMocked("/cart/v1/deviceFingerPrint")
        ? mockPath("Cart", "deviceFingerPrint", ``)
        : `/cart/v1/deviceFingerPrint`,
      method: "POST",
      body: data,
      apiName: "Cart.deviceFingerPrint",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name ReserveWithItem
   * @summary Reserve with Item
   * @request POST:/cart/v1/reserveWithItem
   */
  reserveWithItem = <
    ResT = ReserveWithItemData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: ReserveWithItemPayload,
    query?: {
      /** Context in which we reserve an item */
      context?: "robis";
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/reserveWithItem")
        ? computed(() => mockPath("Cart", "reserveWithItem", ``))
        : computed(() => `/cart/v1/reserveWithItem`),
      method: "POST",
      query: query,
      body: data,
      key: "/cart/v1/reserveWithItem",
      apiName: "Cart.reserveWithItem",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name ReserveWithItem
   * @summary Reserve with Item
   * @request POST:/cart/v1/reserveWithItem
   */
  $reserveWithItem = (
    data: ReserveWithItemPayload,
    query?: {
      /** Context in which we reserve an item */
      context?: "robis";
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<ReserveWithItemData>({
      path: isMocked("/cart/v1/reserveWithItem") ? mockPath("Cart", "reserveWithItem", ``) : `/cart/v1/reserveWithItem`,
      method: "POST",
      query: query,
      body: data,
      apiName: "Cart.reserveWithItem",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name AddPriceAdjustment
   * @summary Add an item level price adjustment
   * @request POST:/cart/v1/cart/{basketId}/price_adjustments
   */
  addPriceAdjustment = <
    ResT = AddPriceAdjustmentData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    basketId: MaybeRefOrGetter<string>,
    data: AddPriceAdjustmentPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/cart/:basketId/price_adjustments")
        ? computed(() => mockPath("Cart", "addPriceAdjustment", `basketId:${toValue(basketId)}`))
        : computed(() => `/cart/v1/cart/${toValue(basketId)}/price_adjustments`),
      method: "POST",
      body: data,
      key: "/cart/v1/cart/:basketId/price_adjustments",
      apiName: "Cart.addPriceAdjustment",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name AddPriceAdjustment
   * @summary Add an item level price adjustment
   * @request POST:/cart/v1/cart/{basketId}/price_adjustments
   */
  $addPriceAdjustment = (basketId: string, data: AddPriceAdjustmentPayload, requestParams: ApiOptions = {}) =>
    this.$request<AddPriceAdjustmentData>({
      path: isMocked("/cart/v1/cart/:basketId/price_adjustments")
        ? mockPath("Cart", "addPriceAdjustment", `basketId:${toValue(basketId)}`)
        : `/cart/v1/cart/${basketId}/price_adjustments`,
      method: "POST",
      body: data,
      apiName: "Cart.addPriceAdjustment",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name UpdatePriceAdjustment
   * @summary Update an item level price adjustment
   * @request PATCH:/cart/v1/cart/{basketId}/price_adjustments/{adjustmentId}
   */
  updatePriceAdjustment = <
    ResT = UpdatePriceAdjustmentData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    basketId: MaybeRefOrGetter<string>,
    adjustmentId: MaybeRefOrGetter<string>,
    data: UpdatePriceAdjustmentPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/cart/:basketId/price_adjustments/:adjustmentId")
        ? computed(() =>
            mockPath(
              "Cart",
              "updatePriceAdjustment",
              `basketId:${toValue(basketId)}.adjustmentId:${toValue(adjustmentId)}`,
            ),
          )
        : computed(() => `/cart/v1/cart/${toValue(basketId)}/price_adjustments/${toValue(adjustmentId)}`),
      method: "PATCH",
      body: data,
      key: "/cart/v1/cart/:basketId/price_adjustments/:adjustmentId",
      apiName: "Cart.updatePriceAdjustment",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name UpdatePriceAdjustment
   * @summary Update an item level price adjustment
   * @request PATCH:/cart/v1/cart/{basketId}/price_adjustments/{adjustmentId}
   */
  $updatePriceAdjustment = (
    basketId: string,
    adjustmentId: string,
    data: UpdatePriceAdjustmentPayload,
    requestParams: ApiOptions = {},
  ) =>
    this.$request<UpdatePriceAdjustmentData>({
      path: isMocked("/cart/v1/cart/:basketId/price_adjustments/:adjustmentId")
        ? mockPath(
            "Cart",
            "updatePriceAdjustment",
            `basketId:${toValue(basketId)}.adjustmentId:${toValue(adjustmentId)}`,
          )
        : `/cart/v1/cart/${basketId}/price_adjustments/${adjustmentId}`,
      method: "PATCH",
      body: data,
      apiName: "Cart.updatePriceAdjustment",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name DeletePriceAdjustment
   * @summary Delete an item level price adjustment
   * @request DELETE:/cart/v1/cart/{basketId}/price_adjustments/{adjustmentId}
   */
  deletePriceAdjustment = <
    ResT = DeletePriceAdjustmentData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    basketId: MaybeRefOrGetter<string>,
    adjustmentId: MaybeRefOrGetter<string>,
    data: DeletePriceAdjustmentPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/cart/v1/cart/:basketId/price_adjustments/:adjustmentId")
        ? computed(() =>
            mockPath(
              "Cart",
              "deletePriceAdjustment",
              `basketId:${toValue(basketId)}.adjustmentId:${toValue(adjustmentId)}`,
            ),
          )
        : computed(() => `/cart/v1/cart/${toValue(basketId)}/price_adjustments/${toValue(adjustmentId)}`),
      method: "DELETE",
      body: data,
      key: "/cart/v1/cart/:basketId/price_adjustments/:adjustmentId",
      apiName: "Cart.deletePriceAdjustment",
      ...params,
    });
  /**
   * No description
   *
   * @tags Cart
   * @name DeletePriceAdjustment
   * @summary Delete an item level price adjustment
   * @request DELETE:/cart/v1/cart/{basketId}/price_adjustments/{adjustmentId}
   */
  $deletePriceAdjustment = (
    basketId: string,
    adjustmentId: string,
    data: DeletePriceAdjustmentPayload,
    requestParams: ApiOptions = {},
  ) =>
    this.$request<DeletePriceAdjustmentData>({
      path: isMocked("/cart/v1/cart/:basketId/price_adjustments/:adjustmentId")
        ? mockPath(
            "Cart",
            "deletePriceAdjustment",
            `basketId:${toValue(basketId)}.adjustmentId:${toValue(adjustmentId)}`,
          )
        : `/cart/v1/cart/${basketId}/price_adjustments/${adjustmentId}`,
      method: "DELETE",
      body: data,
      apiName: "Cart.deletePriceAdjustment",
      ...requestParams,
    });
}
