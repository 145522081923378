<template>
  <vf-accordion v-for="({ richText, title }, i) in accordions" :key="i" :variant="style" :title :icon="iconStyle">
    <lazy-cms-rich-text :content="{ richText, type: 'VfCanvasRichText' }" />
  </vf-accordion>
</template>

<script lang="ts" setup>
import type { AccordionsContent } from '#types/components/cms/accordions'

const { content } = defineProps<{ content: AccordionsContent }>()

const { accordions, style, iconStyle } = content
</script>
