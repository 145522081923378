<template>
  <div :class="{ 'sticky top-0 z-above-header': isStickyPromoBarEnabled && header.sticky.active && showHeader }">
    <client-only>
      <transition name="unfold">
        <lazy-cms-section
          v-if="showPromoBar && !disablePromoBarOnRoutes.includes(route.path) && megaMenu?.promoBar"
          :section="sections.promoBar"
          data-scroll-el="promo-bar"
        />
      </transition>
    </client-only>
  </div>
  <!-- Workaround for a stuck state issue on mobile -->
  <div v-if="header.transparent" class="relative z-1 lg:hidden" style="height: 1px; background: #000000cc" />
  <base-sticky v-slot="{ isStuck }" :margin="offset" name="headerMain">
    <header
      :class="[
        brandClasses.header,
        header.sticky.active && !showHeader ? 'z-0 op-0' : 'z-header',
        { sticky: header.sticky.active },
      ]"
      :style="`top: ${pxToRem(offset)}`"
      data-scroll-el="header"
    >
      <div
        ref="headerRef"
        class="duration"
        :class="{
          'bg-white': (isStuck && y > 0) || menuOpened || !header.transparent || suggestionsOpened,
          'shadow-md <lg:pt-px': header.sticky.active && showHeader && (isStuck && y > 0) && !isSomethingElseSticky,
          'shadow-none absolute inset-x-0': header.transparent && !(isStuck && y > 0),
          '<lg:shadow-md': !header.transparent && !header.sticky.active,
        }"
      >
        <!-- Fader of the transparent header -->
        <div
          v-if="header.transparent"
          class="absolute-0 -z-1"
          style="background: linear-gradient(to top, transparent, rgba(0,0,0,0.8));"
        />
        <vf-utility-navigation
          v-if="links && !customs"
          ref="utilityRef"
          class="relative min-h-8 flex items-center justify-end gap-6 container <lg:hidden"
          :class="[brandClasses.utilityNavigation, { 'c-white': header.transparent && !menuOpened && !suggestionsOpened }]"
          :links
        />
        <div
          ref="navigationRef"
          class="relative flex items-center container between duration"
          :class="[
            brandClasses.primaryNavigation,
            { 'c-white': header.transparent && !(isStuck && y > 0) && !menuOpened && !suggestionsOpened },
          ]"
        >
          <base-link to="/" class="shrink-0" :class="brandClasses.logoLink">
            <slot>
              <vf-logo
                :invert="header.transparent && !(isStuck && y > 0) && !menuOpened && !suggestionsOpened"
                :variant="logoVariant"
                :class="brandClasses.logo"
                class="w-a"
              />
            </slot>
          </base-link>
          <div class="flex grow items-center justify-end lg:justify-between">
            <lazy-cms-section v-if="megaMenu" :section="sections.megaMenu" />
            <div
              class="flex shrink-0 items-center gap-2 lg:self-center <lg:-mr-2"
              :class="brandClasses.iconWrapper"
            >
              <vf-search
                v-model:opened="suggestionsOpened"
                :class="{ '<lg:hidden': customs }"
                :transparent="header.transparent && !(isStuck && y > 0) && !menuOpened && !suggestionsOpened"
              />
              <base-link
                to="/cart"
                class="relative <lg:mx-2"
                :aria-label="cart?.totalItems
                  ? replaceAll($pluralize($t.cartWithItems, cart?.totalItems), { totalItems: cart?.totalItems })
                  : $t.cart"
              >
                <vf-icon name="bag" :size="cartIconSize" />
                <client-only>
                  <span
                    v-if="cart?.totalItems"
                    class="pointer-events-none absolute bottom-0 right-0 h-4 w-4 flex select-none center b-2 b-white rounded-full bg-brand-2 c-white "
                    style="font-size: .5rem"
                    aria-hidden="true"
                  >
                    {{ cart.totalItems }}
                  </span>
                </client-only>
              </base-link>
              <vf-mega-menu-mobile v-if="megaMenu" :mega-menu="megaMenu" :utility-links="links" />
              <vf-button
                v-if="showClearSessionButton && buyInStore.storeInfo?.storeId"
                size="xs"
                :variant="clearSessionButtonVariant"
                class="<md:w-full"
                @click="buyInStore.clearSession"
              >
                {{ $t.clearSession }}
              </vf-button>
            </div>
          </div>
        </div>
      </div>
    </header>
  </base-sticky>
  <lazy-cms-section
    v-if="!header.transparent && !disableBenefitBarOnRoutes.includes(route.path) && megaMenu?.benefitBar"
    :section="sections.benefitBar"
  />
</template>

<script lang="ts" setup>
import { HeaderContextKey } from './context'

const route = useRoute()
const config = useAppConfig()
const { disableBenefitBarOnRoutes, disablePromoBarOnRoutes } = useFeatureFlags()

const { y } = useScroll(document)
const {
  customs,
  menuOpened,
  suggestionsOpened,
  megaMenu,
  sections,
  links,
  isSomethingElseSticky,
  showPromoBar
} = inject(HeaderContextKey)!

const { brandClasses, cartIconSize, isStickyPromoBarEnabled, logoVariant } = config.components.vf.header

const { clearSessionButtonVariant, showClearSessionButton } = config.checkout.buyInStore
const buyInStore = useBuyInStoreStore()
const cart = useCartStore()
const header = useHeaderStore()

const {
  header: headerRef,
  navigation: navigationRef,
  utility: utilityRef,
} = toRefs(header.refs)

const offset = computed(() => (header.height.utility - (isStickyPromoBarEnabled ? header.height.promoBar : 0)) * -1)

// delay showing header if something unsticks as hiding header unsticks everything for one tick
const delayedIsSomethingElseSticky = ref(false)

watch (isSomethingElseSticky, async () => {
  await nextTick()
  delayedIsSomethingElseSticky.value = isSomethingElseSticky.value
})

const showHeader = computed(() =>
  !delayedIsSomethingElseSticky.value || (isSomethingElseSticky.value && header.pageScrolledUp) || false)
</script>
