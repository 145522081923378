import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  componentName: 'mini-cart',
  brandClasses: {
    articles: 'pb-8',
    bonus: 'text-sm uppercase',
    header: {
      wrapper: 'flex items-center between b-b b-grey-50 fw-bold pr-2 pb-2 pl-6 lg:pl-8',
      heading: 'text-sm lg:text-lg',
      separator: 'b-l pl-1'
    },
    footer: 'bg-white p-4 shadow-lg -m-4 space-y-4',
    item: 'line-clamp-3 text-sm fw-medium',
    totalItems: 'm-a min-h-38 w-41 flex center text-center title-3',
    product: 'b-b b-grey-70 py-4 space-y-2',
    quantity: 'line-clamp-1 text-sm mb-4'
  },
  viewCartVariant: 'tertiary',
  dialogOptions: { type: 'panel' }
} satisfies RecursivePartial<DialogConfig['miniCart']>
