<template>
  <img
    :src="`/img/flags/${countryCode || defaultCountryCode}.svg`"
    :alt="$t.locationSelectorCurrentCountry"
    width="18"
    height="12"
    loading="lazy"
  >
</template>

<script lang="ts" setup>
defineProps<{ countryCode?: string }>()

const defaultCountryCode = useCountryCode()
</script>
