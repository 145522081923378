<template>
  <div
    class="w-full"
    :class="brandClasses.container"
    style="min-height: 5rem"
    @click="$emit('recClick', upsell.id)"
  >
    <p v-if="showTitle" class="mb-4" :class="[brandClasses.title, classTitle]">
      {{ $t.productUpsellTitle }}
    </p>
    <div class="flex">
      <div class="h-full flex">
        <vf-checkbox
          v-if="showCheckbox"
          :model-value="modelValue"
          :size="checkboxSize"
          class="self-center pr-4"
          :value="upsell.sku"
          @click="handleGtmEvent($event)"
          @update:model-value="$emit('update:modelValue', $event)"
        />
        <component
          :is="showModal ? BaseButton : BaseLink"
          :to="upsell.url"
          @click="showModal ? openQuickshop() : $gtm.push('upsell.onUpsellDetails', upsell.id)"
        >
          <base-picture
            :src="getImageTransformations(upsell.image, image)"
            :alt="upsell.name"
            :width="image.width"
            :height="image.height"
            :class="brandClasses.details.image"
            :style="`min-width: ${pxToRem(image.width)}`"
          />
        </component>
      </div>
      <div class="mx-4 <lg:space-y-4">
        <div class="text-base space-y-2">
          <p :class="brandClasses.details.text">
            {{ upsellText }}
            <span v-if="showDetails">
              <base-button
                v-if="showModal"
                :class="brandClasses.details.button"
                @click="openQuickshop"
              >
                {{ $t.productUpsellDetails }}
              </base-button>
              <vf-link
                v-else
                :size="detailsLinkSize"
                :class="brandClasses.details.link"
                :to="upsell.url"
                @click="$gtm.push('upsell.onUpsellDetails', upsell.id)"
              >
                {{ $t.productUpsellDetails }}
              </vf-link>
            </span>
          </p>
          <product-pricing :price="upsell.price" :currency="currency[$locale]" :class="brandClasses.details.price" />
        </div>
        <div v-if="showButton" class="mt-3 !mt-4">
          <vf-button :size="buttonSize" :variant="buttonVariant" :loading @click="handleAddToCart">
            {{ $t.productUpsellAddToCart }}
          </vf-button>
        </div>
      </div>
    </div>
  </div>

  <dialog-quickshop size="lg" position="bottom" class-content="<lg:pb-0" />
</template>

<script setup lang="ts">
import { BaseButton, BaseLink } from '#components'
import type { Product, ProductVariants } from '#root/api/clients/product/data-contracts'
import type { CSSClass } from '#types/common'
import type { ProductUpsell, ProductUpsellSelected } from '#types/product'

const props = defineProps<{
  modelValue: string[]
  showTitle?: boolean
  showModal?: boolean
  showButton?: boolean | null
  showCheckbox?: boolean | null
  showDetails?: boolean | null
  upsell: ProductUpsell
  classTitle?: CSSClass
}>()

const emit = defineEmits<{
  'update:modelValue': [payload: ProductUpsellSelected]
  'recClick': [payload: string]
  'openQuickshop': [payload: Product]
}>()

const {
  brandClasses,
  buttonSize,
  buttonVariant,
  checkboxSize,
  detailsLinkSize,
  dialogOptions,
  image
} = useAppConfig().components.product.upsell
const cart = useCartStore()
const { $gtm, $locale, $t, $viewport } = useNuxtApp()
const { currency } = useRuntimeConfig().public
const toast = useToaster()

const { error: cartError, errorMessage } = storeToRefs(cart)
const loading = ref(false)
const upsellText = computed(() => replaceAll($t.productUpsellCopy, { upsellName: props.upsell.name }))

const DialogQuickshop = createDialog('quickshop', {
  ...dialogOptions,
  teleport: () => !$viewport.lg
})

const handleAddToCart = async () => {
  loading.value = true
  await cart.add({ product: props.upsell.sku })

  if (cartError.value) {
    toast.add({
      props: {
        type: 'error',
        title: $t.somethingWentWrong,
        message: errorMessage.value
      }
    })
  }

  loading.value = false
}

const handleGtmEvent = (event: { target: { checked: boolean } }) =>
  event.target.checked
    ? $gtm.push('upsell.onUpsellSelect', props.upsell.id)
    : $gtm.push('upsell.onUpsellDeselect', props.upsell.id)

const openQuickshop = () => {
  emit('openQuickshop', {
    ...props.upsell,
    currency: '',
    attributes: [],
    variants: {} as ProductVariants
  })
  $gtm.push('upsell.onUpsellDetails', props.upsell.id)
}
</script>
