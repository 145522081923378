<template>
  <div
    ref="panelRef"
    class="fixed-0 z-overlay flex"
    :class="[positions[_position], !isX ? size === 'lg' ? 'md:px-4' : 'md:px-5 lg:px-4' : '']"
  >
    <base-dialog-root default-open @update:open="$emit('resolve')">
      <base-dialog-overlay
        class="vf-uki69b"
        :style="[styleBackdrop, hideBackdrop ? '' : 'background: #000000bf']"
        data-test-id="vf-panel-backdrop"
      />
      <vf-dialog-content
        class="vf-vxo7tf"
        :class="[
          transforms[_position],
          isX
            ? [
              'h-full w-100 lg:w-84 p-4',
              brandClasses.content.x,
            ]
            : [
              'w-full lg:w-2/3 px-4 pt-2 md:px-10',
              (size === 'lg' ? 'md:w-full' : 'md:w-2/3'),
              brandClasses.content.y,
            ],
          { 'h-full': size === 'full' },
          classContent,
        ]"
        :style="
          isX
            ? maxPanelSize?.x ? `max-width: ${maxPanelSize.x}` : ''
            : maxPanelSize?.y ? `max-height: ${maxPanelSize.y}` : ''
        "
      >
        <slot />
      </vf-dialog-content>
    </base-dialog-root>
  </div>
</template>

<script lang="ts" setup>
import type { CSSClass } from '#types/common'
import type { SizesSubUnion } from '#types/sizes'
import type { DialogBaseOptions } from '#types/config/components/dialog'

export type Props = {
  /**
   * Size of the panel.
   */
  size?: SizesSubUnion<'sm' | 'lg' | 'full'>
  /**
   * Position of the panel.
   */
  position?: DialogBaseOptions['position']
  /**
   * Classes to be applied to content container.
   */
  classContent?: CSSClass
  /**
   * Classes to be applied to close button.
   */
  classClose?: CSSClass
  styleBackdrop?: string
  /**
   * If backdrop is shown.
   */
  hideBackdrop?: boolean
  /**
   * Whether "close" button is shown.
   */
  hideCloseButton?: boolean
}

const { position } = withDefaults(
  defineProps<Props>(),
  {
    position: 'right',
    size: 'sm'
  }
)

const emit = defineEmits<{
  resolve: []
}>()

const { $viewport } = useNuxtApp()
const { brandClasses, maxPanelSize } = useAppConfig().components.vf.panel

const panelRef = ref()
const _position = isObject(position) ? getValueForBreakpoint($viewport.breakpoint, position)! : position
const isX = computed(() => ['left', 'right'].includes(_position))

const transforms = {
  bottom: '[.nested-enter-from_&,.nested-leave-to_&]:translate-y-20',
  left: '[.nested-enter-from_&,.nested-leave-to_&]:-translate-x-20',
  right: '[.nested-enter-from_&,.nested-leave-to_&]:translate-x-20'
}

const positions = {
  bottom: 'items-end justify-center',
  left: 'items-center justify-start',
  right: 'items-center justify-end'
}

const dirs = {
  left: 'left',
  right: 'right'
}

useSwipe(panelRef, {
  onSwipeEnd(_, dir) {
    if (dirs[dir.toLocaleLowerCase()] === _position) emit('resolve')
  }
})
</script>
