<template>
  <div class="flex gap-6 lg:gap-6 md:gap-4">
    <define-media-template>
      <base-picture
        v-if="responsiveMedia.images"
        v-bind="responsiveMedia.images"
        fit="cover"
        class="full"
      />
      <base-video
        v-if="responsiveMedia.video"
        v-bind="{ ...responsiveMedia.video, ...playerSettings }"
        fit="cover"
        class="full"
        :controls="!playerSettings.hideControls"
      />
    </define-media-template>
    <div
      v-if="mediaPosition === 'left-normal' || mediaPosition === 'left-large'"
      class="hidden shrink-0 -my-10 -ml-4 md:block md:-ml-10"
      :class="{
        'w-73': mediaPosition === 'left-normal',
        'w-112': mediaPosition === 'left-large',
      }"
    >
      <media-template />
    </div>
    <div class="w-full f-col space-y-6" :class="alignmentClasses[alignment]">
      <div
        v-if="mediaPosition === 'top' || mediaPosition === 'left-normal' || mediaPosition === 'left-large'"
        class="w-full"
        :class="{ 'md:hidden': mediaPosition === 'left-normal' || mediaPosition === 'left-large' }"
      >
        <media-template />
      </div>
      <div
        v-if="title || richText"
        class="space-y-2"
      >
        <h2 v-if="title" v-style:c="titleColor" :class="titleStyle">
          {{ title }}
        </h2>
        <p
          v-if="subtitle"
          v-style:c="subtitleColor"
          :class="subtitleStyle"
        >
          {{ subtitle }}
        </p>
        <cms-rich-text v-if="richText" v-style:c="richTextColor || subtitleColor" :content="{ richText }" />
      </div>
      <media-template v-if="mediaPosition === 'body'" />
      <div v-if="targets.length" class="flex gap-4 wrap lg:gap-6">
        <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" :size="{ sm: { size: 'sm' } }" />
      </div>
      <cms-rich-text
        v-if="richTextBelowTargets"
        v-style:c="richTextBelowTargetsColor || subtitleColor"
        :content="{ richText: richTextBelowTargets }"
      />
      <form-newsletter
        v-if="newsletterForm"
        class="text-left"
        :class-heading="formAlignmentClasses[alignment]"
        :class-subheading="formAlignmentClasses[alignment]"
        :class-submit="formAlignmentClasses[alignment]"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TextAlignment } from '#types/components/cms/text-alignment'
import type { DialogContent } from '#types/components/cms/dialog'
import type { PlayerSettings } from '#types/components/cms/video-player'

const { content } = defineProps<{
  content: DialogContent
}>()

const [DefineMediaTemplate, MediaTemplate] = createReusableTemplate()

const {
  alignment = 'left',
  media,
  mediaPosition = 'top',
  newsletterForm,
  playerSettings = {} as PlayerSettings,
  richText,
  richTextColor,
  richTextBelowTargets,
  richTextBelowTargetsColor,
  subtitle,
  subtitleColor,
  subtitleStyle,
  targets = [],
  title,
  titleColor,
  titleStyle,
} = content

const { getMedia } = useCms()

const responsiveMedia = getMedia(media)

const alignmentClasses: Record<TextAlignment, string> = {
  left: 'items-start text-left',
  center: 'items-center text-center',
  right: 'items-end text-right'
}

const formAlignmentClasses: Record<TextAlignment, string> = {
  left: 'md:text-left! md:self-start!',
  center: 'md:text-center! md:self-center!',
  right: 'md:text-right! md:self-end!',
}
</script>
