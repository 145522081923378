<template>
  <define-content-template>
    <vf-icon v-if="icon" :name="icon.icon" size="md" :dir="icon.dir" />
    <span :class="{ 'sr-only': iconOnly }">
      {{ text }}
    </span>
  </define-content-template>
  <vf-link
    v-if="shouldRenderTextLink"
    v-style:display="textLinkDisplay"
    v-style:c="textLinkColor"
    class="gap-1"
    :aria-label="iconOnly ? text : undefined"
    :class="[
      { 'w-full': size?.sm?.fullWidth },
      { 'flex-row-reverse': iconPosition === 'right' },
      textLinkStyle,
    ]"
    :style="{
      '--ch': textLinkHoverColor,
    }"
    :to="fullUrl"
    :target="targetAttribute"
    :variant="textLinkUnderline === 'never' ? 'quieter' : (textLinkUnderline === 'hover' ? 'quiet' : undefined)"
    v-bind="$attrs"
  >
    <content-template />
  </vf-link>
  <vf-button
    v-if="shouldRenderButton"
    v-style:display="buttonDisplay"
    :aria-label="iconOnly ? text : undefined"
    :class="{ 'w-full': size?.sm?.fullWidth }"
    :class-content="[{ 'flex-row-reverse': iconPosition === 'right' }, 'gap-1']"
    :size="buttonSize"
    :to="fullUrl"
    :target="targetAttribute"
    :variant="style"
    v-bind="$attrs"
  >
    <content-template />
  </vf-button>
</template>

<script lang="ts" setup>
import type { ButtonSize } from '#types/components/vf/button'
import type { Responsive } from '#types/common'
import type { Target } from '#types/target'

const props = defineProps<Target>()

const [DefineContentTemplate, ContentTemplate] = createReusableTemplate()

const {
  size = {} as Responsive<{ size?: ButtonSize, fullWidth?: boolean }>,
  style,
  text,
  url = '',
  icon,
  targetAttribute,
  iconPosition
} = props

const route = useRoute()
const router = useRouter()

const shouldRenderTextLink = computed(() => Object.values(style || {}).includes('text-link'))
const shouldRenderButton = computed(() => Object.values(style || {}).some((val) => val !== 'text-link'))

const textLinkDisplay = computed(() => {
  return Object.entries(style || {}).reduce((acc, [key, value]) => {
    acc[key] = value === 'text-link' ? 'inline-flex' : 'none'
    return acc
  }, {})
})

const buttonDisplay = computed(() => {
  return Object.entries(style || {}).reduce((acc, [key, value]) => {
    acc[key] = value !== 'text-link' ? 'inline-flex' : 'none'
    return acc
  }, {})
})

const fullUrl = computed(() => {
  if (url.startsWith('#modal')) {
    return `${route.fullPath.toString()}${url}`
  }
  else if (url.startsWith('?') && url.includes('#modal')) {
    const [urlQuery, urlHash] = url.substring(1).split('#')
    const additionalParams = Object.fromEntries((new URLSearchParams(urlQuery)).entries())
    return router.resolve({
      query: { ...route.query, ...additionalParams },
      hash: urlHash ? `#${urlHash}` : ''
    }).href
  }
  return url
})

const { sm: { size: sm = 'sm' } = {}, md, lg } = size

const buttonSize: ButtonSize | Responsive<ButtonSize> = {
  sm,
  md: md?.size,
  lg: lg?.size
}
</script>
