/**
 * @remark Store could be anything like user, cart, screen.
 * The first argument is a unique id of the store across your application
 */
import { ApiErrorCode } from '#root/enums/api'
import type {
  ApproachingDiscount,
  CartGroupedItems,
  CartItem,
  CartStoreContext,
  CartTotals,
  Coupon,
  CustomerNotification,
  FlashError,
  FlashErrorDetails,
  FlashErrorType,
  GiftOption,
  NotificationLevel,
  PaymentInstrument,
  SavedItems,
  ShippingAddress,
  ShippingMethod,
  ShippingMethodCode
} from '#types/cart'

import type { GetPaymentMethodsData, PromoSummary, UpdateItemPayload } from '#root/api/clients/cart/data-contracts'
import type { GetStoreInventoryData } from '#root/api/clients/product/data-contracts'
import type { ApiError } from '#root/api/util/error'
import type { CartStoreAddress } from '#types/applePay'
import type { PaymentMethodCode } from '#types/components/checkout/paymentMethods'
import type { BaseNotification } from '#types/notification'

function isErrorCartNotFound(err) {
  return err?.errorId === ApiErrorCode.CART_NOT_FOUND
}

function isErrorInvalidCustomer(err) {
  return err?.errorId === ApiErrorCode.INVALID_CUSTOMER
}

function splitPromoMessages(calloutMessage: string): string[] {
  return calloutMessage.split('|').map((msg: string) => msg.trim())
}

export const useCartStore = (storeType = '') => defineStore(`cart${storeType}`, () => {
  const { cart, products, wishlists } = useApi()
  const config = useAppConfig()
  const {
    cart: { savedForLater: { savedForLaterPageSize } },
    checkout: { cartContainer: { sortBonusItemsFirst } }
  } = config.components
  const { loyaltyType } = config.pages.checkout
  const { allowSharedCookies } = config.api
  const { DialogMiniCart } = useDialogsStore()
  const {
    allowPickupOrder,
    enableRelativePdpUrl,
    enableShippingAddressRecaptcha,
    showMiniCartOnAddProduct
  } = useFeatureFlags()
  const { $dtrum, $t } = useNuxtApp()
  const toast = useToaster()
  const route = useRoute()
  const buyInStore = useBuyInStoreStore()
  const employeeApiCookie = useApiCookie(cookiesPostfix.Employee)

  const basketId = ref('')
  const currency = ref<string>(useCurrencyCode())
  const totalItems = ref(0)
  const totals = ref<CartTotals>({
    total: 0,
    totalDiscount: 0,
    itemTotal: 0,
    totalWithoutTax: 0,
    shipping: 0,
    tax: 0,
    totalDiscountPercentage: 0,
    remainingToPay: 0,
    currency: '',
    totalUnits: 0,
    itemSubTotal: 0,
    netAmount: 0,
    taxAmount: 0,
    appliedRewards: 0
  })
  const items = ref<CartItem[]>([])
  const savedItems = ref<SavedItems>({} as SavedItems)
  const shippingMethods = ref<ShippingMethod[]>([])
  const paymentInstruments = ref<PaymentInstrument[]>([])
  const paymentMethods = ref<GetPaymentMethodsData>([])
  const couponItems = ref<Coupon[]>([])
  const orderPromotions = ref<any>([])
  const promoSummary = ref<PromoSummary[]>([])
  const pending = ref(false)
  const pendingShippingAddress = ref(false)
  const loadingApplicableShippingMethods = ref(false)
  const error = ref(false)
  const errorMessage = ref('')
  const errorTitle = ref('')
  const giftCardsBalances = ref(new Map<string, number>())
  const favoriteStoreId = ref('')
  const cachedCustomerNotifications = ref<Record<string, CustomerNotification[]>>({})
  const isCartMerged = ref(false)
  const gwpMessage = ref('')
  const approachingPromotionMessage = ref('')
  const savedCartPending = ref(false)
  const verifiedShippingAddress = ref<ShippingAddress | null>()
  const selectedUpsells = ref<string[]>([])
  const notification = reactive<BaseNotification & { level: NotificationLevel }>({
    type: '',
    message: '',
    level: 'top'
  })

  const setNotification = (type: BaseNotification['type'], message: string, level?: NotificationLevel) => {
    notification.type = type
    notification.message = message
    if (level) notification.level = level

    if (message && notification.level === 'top') {
      scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }

  const clearNotification = () => {
    notification.type = ''
    notification.message = ''
  }

  const flash = ref<FlashError[]>([])
  // out of stock items coming from flash messages
  const outOfStockItems = ref<(FlashErrorDetails & { reasonMessage?: string })[]>([])

  const mergedCartNotificationActive = computed(() => notification.message === $t.cartItemsMerged)
  const itemOOSNotificationActive = computed(() => notification.message === $t.outOfStockNotification)
  const noApplicableDeliveryNotificationActive = computed(() => notification.message === $t.apiMessages.SHP500)
  // check for the context of the pinia store to decide on passing a necessary query parameter (it can be done with switch if need more contexts in the future)
  const queryParams = computed(() => storeType === 'applePayPdp' ? { bid: 'cookie' } : undefined)

  const reset = () => {
    basketId.value = ''
    currency.value = useCurrencyCode()
    totalItems.value = 0
    totals.value = {
      total: 0,
      totalDiscount: 0,
      itemTotal: 0,
      totalWithoutTax: 0,
      shipping: 0,
      tax: 0,
      totalDiscountPercentage: 0,
      remainingToPay: 0,
      currency: '',
      totalUnits: 0,
      itemSubTotal: 0,
      netAmount: 0,
      taxAmount: 0,
      appliedRewards: 0
    } as CartTotals
    items.value = []
    savedItems.value = {} as SavedItems
    shippingMethods.value = []
    paymentInstruments.value = []
    paymentMethods.value = []
    orderPromotions.value = []
    promoSummary.value = []
    pending.value = false
    pendingShippingAddress.value = false
    loadingApplicableShippingMethods.value = false
    errorMessage.value = ''
    errorTitle.value = ''
    error.value = false
    notification.message = ''
    giftCardsBalances.value = new Map()
    outOfStockItems.value = []
  }

  const resetFlashMsg = (code: FlashErrorType) => {
    if (flash.value.find((msg) => msg.code === code))
      flash.value = flash.value.filter((msg) => msg.code !== code)
  }
  /** Sets Merge Cart message to toast if needed. Moved out separately due to SQ complexity complaint. */
  const setMergeCartNotification = ({ keepNotifications, itemOOSNotificationActive }) => {
    // if we have OOS items and should keep notifications we wanna keep the merge cart notification as a toast. (https://digital.vfc.com/jira/browse/GLOBAL15-54905)
    if (keepNotifications && itemOOSNotificationActive)
      isCartMerged.value = true
  }
  /**
   * To reduce SQ cognitive complexity, we moved the logic for clearing OOS items and notifications to a separate function.
   * @param keepNotifications - should we keep notifications
   */
  const clearOOS = (keepNotifications: boolean) => {
    // reset outOfStock notifications if there
    if (itemOOSNotificationActive.value && !keepNotifications) {
      notification.message = ''
    }
    // if we're keeping notifications we should keep the outOfStock items too to be able to show them.
    else if (!keepNotifications) {
      outOfStockItems.value = []
      notification.message = ''
    }
  }
  const setMessagePerCode = (code: FlashErrorType) => (
    (code === 'ProductLineItemFullInventoryMissing' && !items.value.length)
      ? $t.orderCanNotBeCompletedOOS
      : $t.outOfStockNotification
  )
  // helper to reduce congitive complexity
  const flashItemBasePrice = (curent = 0, missing = 0) => {
    return curent * missing
  }
  // helper to reduce congitive complexity
  const needsShippingAddress = (code, flashMessage) => {
    return code === 'InvalidShippingAddress' && flashMessage.details?.shipmentId
  }
  /**
   * handles the flash messages
   * @param flashMessages - FlashError
   * Should be used to handle different use cases for diffrent flash codes
   * @param {boolean} keepNotifications - should we keep notifications through the first pass in flash messages handler
   */
  const handleFlashMessages = (flashMessages: FlashError[], keepNotifications: boolean) => {
    clearOOS(keepNotifications)
    flashMessages.forEach((flashMessage) => {
      const code = flashMessage.code || flashMessage.type
      const productId = flashMessage.details?.sku || flashMessage.details?.productId

      if (outOfStockItems.value.find((item) => item.productId === productId)) return

      const messageLevel: NotificationLevel = 'item'

      if (code && [
        'ProductLineItemInventoryMissing',
        'ProductItemNotAvailable',
        'ProductLineItemFullInventoryMissing'
      ].includes(code) && flashMessage.details?.productId) {
        // need flashMessage.details?.productId here because only valid flash errors have productId otherwise it will be overwritten by empty object

        let additionalData = {}
        const isPartialOos = code === 'ProductLineItemInventoryMissing'
        // grab additional data from the cart for partial oos item (missing in the response inside flash object)
        const mapProductInCart = items.value.find((product) => product.productId === productId)
        if (isPartialOos) {
          additionalData = {
            productSlug: mapProductInCart?.pdpUrl,
            basePrice: flashItemBasePrice(mapProductInCart?.price?.current, flashMessage.details?.missingQty),
            id: mapProductInCart?.id,
            masterProductId: mapProductInCart?.masterId2,
            variationGroupId: mapProductInCart?.masterId
          }
        }
        outOfStockItems.value.push({
          ...mapProductInCart,
          ...flashMessage.details,
          isPartialOos,
          ...additionalData
        })

        const message = setMessagePerCode(code)
        setMergeCartNotification({ keepNotifications, itemOOSNotificationActive: itemOOSNotificationActive.value })
        setNotification('error', message, messageLevel)
      }
      else if (code && [
        'PickupToSthTransition',
        'PickupToStsTransition',
        'StsToSthTransition',
        'StsToPickupTransition',
        'STHToPickupTransition'
      ].includes(code) && flashMessage.details?.productId) {
        const mapProductInCart = items.value.find((product) => product.productId === productId)
        outOfStockItems.value.push({
          ...mapProductInCart,
          ...flashMessage.details,
          reasonMessage: $t.itemDeliveryMethodChanged
        })

        setNotification('error', $t.apiMessages.general.ORDER_UPDATED, messageLevel)
      }
      // Only error response (4xx) has the shipmentId property so we don't show the notification for success response (2xx) even with flash messages in it
      else if (needsShippingAddress(code, flashMessage)) {
        setNotification('error', $t.apiMessages.general.INVALID_SHIPPING_ADDRESS, messageLevel)
      }
    })
  }

  const recreateCart = async (itemsToRemove?: string[]) => {
    pending.value = true

    try {
      const itemsToBeRemoved = items.value?.filter((it) => !itemsToRemove?.includes(it.id)).map((it) => {
        return {
          productId: it.productId,
          qty: it.qty,
          maxQty: it.maxQty,
          upc: it.upc
        }
      })

      reset()
      const response = await cart.$create({})

      basketId.value = response?.id || ''
      try {
        await cart.$addItem(itemsToBeRemoved?.map((item) => ({
          ...item,
          cartId: basketId.value
        })))
      }
      catch (e) {
        setNotification('error', $t.cartExpiredMessage)
      }
    }
    catch (err) {
      reset()
    }
    finally {
      pending.value = false
    }
  }

  // To handle the AUT498 error code for invalid customer case, the cart needs to be
  // recreated and the page reloaded
  // HOTFIX; https://digital.vfc.com/jira/browse/GLOBAL15-59506
  const clearAndRecreateCart = async () => {
    localStorage.removeItem('cart')
    await recreateCart()
    toast.add({
      autoClose: false,
      props: {
        message: $t.invalidCustomerCart,
        type: 'error'
      }
    })
    setTimeout(() => {
      location.reload()
    }, 4000)
  }

  const getItemsStoreInventory = async () => {
    try {
      pending.value = true
      // Get cart item store inventory info when the pickup store being selected
      let storeInventoryResponses: GetStoreInventoryData[] = []
      const storeInfoItems = items.value.filter(({ shippingOptions }) =>
        shippingOptions.some(({ storeInfo }) => storeInfo?.id))
      if (storeInfoItems.length) {
        storeInventoryResponses = await Promise.all(storeInfoItems
          .map((item) => products.$getStoreInventory(
            item.productId,
            [{ storeId: item.shippingOptions.find(({ storeInfo }) => storeInfo)?.storeInfo?.id ?? '' }]
          )))
      }
      items.value = items.value.map((item) => ({
        ...item,
        shippingOptions: item.shippingOptions.map((option) => {
          const storeInventory = storeInventoryResponses.find(({ productId }) => productId === item.productId)
            ?.storeInventory?.find(({ storeId }) => storeId === option.storeInfo?.id)
          return {
            ...option,
            storeInfo: option?.storeInfo
              ? { ...option.storeInfo, storeInventory: storeInventory?.quantity ?? 0 }
              : undefined
          }
        })
      }))
    }
    catch (err) {
      console.error(err)
    }
    finally {
      pending.value = false
    }
  }

  const orderPromoSummary = computed(() => promoSummary.value
    ?.filter(({ level }) => level === 'ORDER')
    .map((promo) => ({
      ...promo,
      calloutMessage: (promo.calloutMessage && splitPromoMessages(promo.calloutMessage)?.[2]) || promo.calloutMessage
    }))
  )
  const productPromoSummary = computed(() => promoSummary.value?.filter(({ level }) => level === 'PRODUCT'))
  const shippingPromoSummary = computed(() => promoSummary.value?.filter(({ level }) => level === 'SHIPPING'))

  const updateCartData = (response: any, {
    emitUpdateEvent = true,
    keepNotifications = false
  } = {}) => {
    const { $gtm } = useNuxtApp()
    totals.value = {
      ...response.totals,
      totalDiscountPercentage: getPercentage(response.totals.itemTotal, response.totals.totalDiscount)
    }
    totalItems.value = response.totalItems
    currency.value = response.currency
    items.value = (response.items || []).map((item) => transformCartItem(item, { $t, enableRelativePdpUrl }))

    paymentInstruments.value = response.payment_instruments || []
    shippingMethods.value = (response.shippingMethods || []).map((method) => ({
      ...method,
      applicableShippingMethods: method.applicableShippingMethods
      || shippingMethods.value.find(({ shippingId }) => shippingId === method.shippingId)?.applicableShippingMethods
    }))
    couponItems.value = response.couponItems || []
    orderPromotions.value = response.orderPromotions || []
    // if promo name is not available, use promo id
    promoSummary.value = (response.promoSummary || []).map((promo) =>
      ({ ...promo, promotionName: promo.promotionName || promo.promotionId }))
    // handle flash messages. if we keep notifications keep the previous value
    flash.value = response?.flash || (keepNotifications ? flash.value : [])
    // calling this every time in case we need to clear outOfStockNotification
    handleFlashMessages(flash.value, keepNotifications)

    if (emitUpdateEvent)
      $gtm.push('cart.onCartUpdate', response)
  }

  const getApplicableShippingMethods = async () => {
    try {
      loadingApplicableShippingMethods.value = true
      if (!shippingMethods.value.length) return

      const meAndCustomsShippingMethods = shippingMethods.value
        .filter(({ shippingId }) => ['me', 'customs'].includes(shippingId))
      const applicableShippingMethodsResp = (await Promise.all(meAndCustomsShippingMethods
        .map(({ shippingId }) => cart.$getApplicableShippingMethods(basketId.value, shippingId, queryParams.value))))
        .map((resp, i) => ({
          ...resp,
          shippingId: meAndCustomsShippingMethods[i].shippingId,
          applicableShippingMethods: resp.shippingMethods?.map((applicableMethod) => ({
            ...applicableMethod,
            selected: applicableMethod.code === resp.defaultShippingMethodId
          })) as ShippingMethod['applicableShippingMethods']
        }))
      shippingMethods.value = shippingMethods.value.map((method) => {
        const applicableShippingMethods = applicableShippingMethodsResp
          .find(({ shippingId }) => shippingId === method.shippingId)?.applicableShippingMethods

        return applicableShippingMethods ? { ...method, applicableShippingMethods } : method
      })
    }
    finally {
      loadingApplicableShippingMethods.value = false
    }
  }

  const getUpdateItemPayload = (cartId, item: CartItem): UpdateItemPayload => ({
    cartId,
    productId: item.productId,
    itemId: item.id,
    qty: item.qty,
    maxQty: item.maxQty,
    pdpUrl: item.pdpUrl,
  })

  const updateItemShippingMethod = async (item: CartItem, storeId?: string, updateInventory?: boolean) => {
    try {
      pending.value = true
      const response = await cart.$updateItem(basketId.value, {
        ...getUpdateItemPayload(basketId.value, item),
        storeId: storeId ?? ''
      }, { action: 'pickup', favStoreId: storeId || item.shippingOptions.find(({ selected }) => selected)?.storeInfo?.id })
      updateCartData(response, { emitUpdateEvent: false })
      if (updateInventory) await getItemsStoreInventory()
      error.value = false
      errorMessage.value = ''
      errorTitle.value = ''
    }
    catch (err) {
      assertApiError(err)
      setNotification('error', err.message, 'top')
    }
    finally {
      pending.value = false
      DialogMiniCart.close()
    }
  }

  type GiftOptionPayload = { giftOption: GiftOption, isGiftBoxSelected?: boolean }

  const updateItemGiftOption = async (item: CartItem, payload: GiftOptionPayload | null) => {
    const { giftOption, isGiftBoxSelected = false } = payload || {}
    try {
      pending.value = true
      const response = await cart.$updateItem(basketId.value, {
        ...getUpdateItemPayload(basketId.value, item),
        gift: !!giftOption,
        giftOption: giftOption || { to: '', from: '', message: '' },
        isGiftBoxSelected
      })

      updateCartData(response, { emitUpdateEvent: false })
      error.value = false
      errorMessage.value = ''
      errorTitle.value = ''
    }
    catch (err) {
      assertApiError(err)
      setNotification('error', err.message, 'top')
    }
    finally {
      pending.value = false
      DialogMiniCart.close()
    }
  }

  const getFavoriteStoreId = (isPatchRequired: boolean) => {
    return (allowPickupOrder && isPatchRequired) ? favoriteStoreId.value : undefined
  }

  const fetchCartData = async (
    favStoreId: string | undefined,
    inventorySupplyCheck: boolean | undefined,
    isPatchRequired: boolean | undefined
  ) => {
    return cart.$get(basketId.value, {
      favStoreId: favStoreId || undefined,
      isPatchRequired: !!favStoreId || isPatchRequired || undefined,
      inventorySupplyCheck: inventorySupplyCheck || undefined
    })
  }

  const getExtraInfoMessage = (err) => {
    if (err.errorDetails[0]?.message?.length) {
      switch (err.errorDetails[0]?.message[0].type) {
        case 'ProductItemMaxQuantity':
          return $t.apiMessages.orderUpdatedExtraInfo.ProductItemMaxQuantity
        case 'ProductItemRequired':
          return $t.apiMessages.orderUpdatedExtraInfo[err.errorDetails[0]?.message[2].type]
      }
    }
    return ''
  }

  const getNotificationLevel = (err): NotificationLevel => {
    if ([ApiErrorCode.ITEM_NOT_AVAILABLE, ApiErrorCode.NO_PRICE, ApiErrorCode.INVALID_PRICE].includes(err.errorId))
      return 'item'
    return 'top'
  }

  /**
   * 1. Check if cart response has a non-threshold type approachingDiscount, set approach discount notification to show the customer needs spend more to achieve the Free Gift promotion
   * 2. Check if cart response has a non-threshold type promoSummary and at least one bonus item, set free gift eligible notification when the cart has the free gift
   */
  const processGwpPromotion = ({ approachingDiscount, promoSummary, items }: {
    approachingDiscount?: ApproachingDiscount[]
    promoSummary?: PromoSummary[]
    items: CartItem[]
  }) => {
    if (route.name?.toString().includes('cart')) {
      const discount = approachingDiscount?.find(({ promotionId }) => !promotionId.startsWith('approaching-'))
      if (discount && discount.distanceFromConditionThreshold) {
        gwpMessage.value = replaceAll($t.approachDiscountNotification, {
          approachDiscount: useFormattedPrice(discount.distanceFromConditionThreshold, currency.value)
        })
        return
      }

      const appliedPromo = promoSummary?.find(({ promotionId }) => !promotionId.startsWith('approaching-'))
      if (appliedPromo && items.some(({ bonus }) => bonus)) {
        gwpMessage.value = $t.freeGiftWithPurchase
        return
      }

      gwpMessage.value = ''
    }
  }

  /**
   * 1. Check if cart response has approachingDiscount with ID prefixed with `approaching-`, set approach discount notification to show the customer needs spend more to meet promotion criteria
   * 2. Check if cart response has entry in the promoSummary collection with ID prefixed with `approaching-`, set discount notification message visible on cart and mini cart
   */
  const processApproachingPromotions = ({ approachingDiscount, promoSummary, items }: {
    approachingDiscount?: ApproachingDiscount[]
    promoSummary?: PromoSummary[]
    items: CartItem[]
  }) => {
    // TODO: GLOBAL15-97592
    // current implementation does not handle different types of benefits well (gift, free delivery, discount)
    // a more comprehensive solution needs to be designed
    processGwpPromotion({ approachingDiscount, promoSummary, items })

    const discount = approachingDiscount?.find(({ promotionId }) => promotionId.startsWith('approaching-'))
    if (discount) {
      // the callout message property is expected to have messaging for all stages split by pipe character "|"
      const messages = splitPromoMessages(discount.calloutMsg)
      if (messages.length !== 3) return // skip if promotion is misconfigured
      approachingPromotionMessage.value = discount.distanceFromConditionThreshold
        ? replaceAll(messages[1], {
          distanceFromConditionThreshold: useFormattedPrice(discount.distanceFromConditionThreshold, currency.value)
        })
        : messages[0]
      return
    }

    const appliedPromo = promoSummary?.find(({ promotionId }) => promotionId.startsWith('approaching-'))
    if (appliedPromo?.calloutMessage) {
      const messages = splitPromoMessages(appliedPromo.calloutMessage)
      if (messages.length !== 3) return // skip if promotion is misconfigured
      approachingPromotionMessage.value = messages[2]
      return
    }

    approachingPromotionMessage.value = ''
  }

  /**
   * get - gets the cart with the latest changes from the server
   * @param {object} params
   * @param {boolean} params.inventorySupplyCheck - if set to true will request directly the OMS to validate the inventory. Please use it only on prepareOrder or mergeCart flows
   * @param {boolean} params.mergeCarts - cart will be merged if set to true
   * @param {boolean} params.isPatchRequired
   * @param {boolean} params.keepNotifications - should we keep notifications through the first pass in flash messages handler, default is false
   */
  const get = async ({
    inventorySupplyCheck = false,
    mergeCarts = false,
    isPatchRequired = false,
    keepNotifications = false
  } = {}) => {
    pending.value = true
    try {
      if (!employeeApiCookie.value && allowSharedCookies) {
        // When allowing shared cookies, we must always get the cart id from the server. This is because the user could have changed their id by authenticating, or any other action on the other page we are sharing cookies with
        const response = await cart.$create({})
        if (response.id)
          basketId.value = response.id
      }

      if (basketId.value) {
        const favStoreId = getFavoriteStoreId(isPatchRequired)
        const response = await fetchCartData(favStoreId, inventorySupplyCheck, isPatchRequired)

        updateCartData(response, { emitUpdateEvent: !!mergeCarts, keepNotifications })
        await getItemsStoreInventory()
        processApproachingPromotions(response)

        // After setting feature flag allowPickupOrder to false, change all pickup items in cart to ship-to-home
        const pickupItems = (response.items || []).filter((item) =>
          isPickupOrSts(item.shippingOptions?.find(({ selected }) => selected)?.shippingMethod.code))
        if (!allowPickupOrder && pickupItems.length)
          await Promise.all(pickupItems.map((pickupItems) => updateItemShippingMethod(pickupItems)))
      }
    }
    catch (err) {
      assertApiError(err)

      if (isErrorInvalidCustomer(err))
        clearAndRecreateCart()

      if (isErrorCartNotFound(err)) {
        await recreateCart()
        return get()
      }
      const extraInfoMessage = getExtraInfoMessage(err)
      const notificationLevel = getNotificationLevel(err)

      setNotification('error', replaceAll(err.message, { extraInfoMessage }), notificationLevel)
    }
    finally {
      pending.value = false
    }
  }

  /**
   * Updates cart id when needed
   * @param {string} newBasketId - the new basket id for the user
   * @param {boolean} refresh - should we make additional cart get call to refresh the cart
   */
  const updateCartId = async (newBasketId: string, refresh: boolean) => {
    const previousTotalItems = totalItems.value

    basketId.value = newBasketId
    if (refresh)
      await get({ mergeCarts: true, isPatchRequired: !!favoriteStoreId.value, keepNotifications: true })

    if (previousTotalItems && totalItems.value > previousTotalItems) {
      // if we merge carts and we have OOS, we should show merge carts message and OOS message both as notification bar.
      if (!itemOOSNotificationActive.value) {
        isCartMerged.value = true
        return
      }

      setMergeCartNotification({ keepNotifications: true, itemOOSNotificationActive: true })
    }
  }

  const hasCustomsProduct = computed(() => items.value.some((item) => item.isCustoms))

  const getGiftOptionObject = (giftOption?: GiftOption) => giftOption
    ? {
        isDigitalProduct: true,
        giftOption: {
          email: giftOption.email || '',
          to: giftOption.to || '',
          message: giftOption.message || '',
        }
      }
    : {}

  const getItemsToAdd = (
    product: string | string[],
    upc?: string,
    giftOption?: GiftOption,
    maxQty?: number,
    storeId?: string,
    recipeId?: string,
    productImageURL?: string
  ) => Array.isArray(product)
    ? product.map((item) => {
      return {
        productId: item,
        upc,
        maxQty: maxQty ?? 999999,
        qty: 1,
        storeId,
        recipeId,
        productImageURL,
        ...getGiftOptionObject(giftOption)
      }
    })
    : [{
        productId: product,
        upc,
        maxQty: maxQty ?? 999999,
        qty: 1,
        storeId,
        recipeId,
        productImageURL,
        ...getGiftOptionObject(giftOption)
      }]

  /**
   * Get the max allowed quantity message from the error object
   * @param {ApiError} err
   */
  const getMaxAllowedQtyMessage = (err: ApiError) => {
    try {
      // optional chaining here would cause Sonar Qube complexity to go up so use try instead
      const maxAllowedQty = err.response?._data.errorDetails[1]
        .additionalInfo[0].argument.statusDetails.additionalDetails.maxAllowedQty

      // if for example the whole statement doesn't throw an error but maxAllowedQty is undefined
      if (!maxAllowedQty) throw new Error('maxAllowedQty is undefined')

      return replaceAll($t.cannotAddMoreSameItems, { maxAllowedQty })
    }
    catch (e) {
      // have to do it in catch block because of eslint Unsafe usage of ReturnStatement in finally block
      return $t.apiMessages[ApiErrorCode.ADDED_MAX_QUANTITY]
    }
  }

  /**
   * Add item to the cart via api and update the store
   * @param {string | string[]} product - the product variant id(s) to be added
   */

  const add = async ({
    product,
    upc,
    addToCartContextKey,
    suppressMiniCart,
    giftOption,
    customAmount,
    maxQty,
    extendedGtmConfig,
    storeId,
    recipeId,
    productImageURL
  }:
  {
    product: string | string[]
    upc?: string
    addToCartContextKey?: CartStoreContext
    suppressMiniCart?: boolean
    giftOption?: GiftOption
    customAmount?: { amount?: number }
    maxQty?: number
    extendedGtmConfig?: {
      list?: string
      category?: string
      eventAction?: string
      bundleId?: string
    }
    storeId?: string
    recipeId?: string
    productImageURL?: string
  }) => {
    const { $gtm } = useNuxtApp()
    let response = {} as any
    pending.value = true
    try {
      if (selectedUpsells.value.length >= 1)
        product = Array.isArray(product) ? [...product] : [product]

      const itemsToAdd = getItemsToAdd(product, upc, giftOption, maxQty, storeId, recipeId, productImageURL)

      const reqQueryParams = {
        ...queryParams.value,
        ...customAmount,
        ...(storeId && { action: 'pickup', favStoreId: storeId })
      }
      if (addToCartContextKey === 'applePayPdp') {
        const data = await cart.$createWithItem(itemsToAdd, reqQueryParams)
        response = data
        basketId.value = response.id
      }
      else {
        if (basketId.value) {
          const data = await cart.$addItem(
            itemsToAdd.map((item) => ({
              ...item,
              cartId: basketId.value
            })),
            {
              query: reqQueryParams
            }
          )
          response = data
        }
        else {
          const data = await cart.$createWithItem(itemsToAdd, reqQueryParams)
          response = data
          basketId.value = response.id
        }
      }
      $gtm.push('cart.onAddToCart', response, itemsToAdd, {
        breadcrumbs: deserializeAnalyticsBreadcrumbs(history.state.breadcrumbs),
        category: history.state.category,
        searchTerm: history.state.searchTerm,
        ...extendedGtmConfig
      })

      updateCartData(response)
      processApproachingPromotions(response)
      response.items.forEach(({ id, customerNotifications }) => {
        if (customerNotifications) {
          const bopisNotification = customerNotifications.find((customerNotification) => ['ChangedStoreId', 'StsToSthTransition'].includes(customerNotification.type))
          if (bopisNotification) {
            cachedCustomerNotifications.value[id] = [{
              ...bopisNotification,
              message: bopisNotification.message || $t.itemDeliveryDetailsChanged
            }]
          }
        }
      })

      // display the mini cart upon successful add-to-cart
      // when it's feature flag is enabled and not adding from PDP page
      if (showMiniCartOnAddProduct && !suppressMiniCart && !addToCartContextKey && !route.name?.toString().includes('cart')) {
        DialogMiniCart.open()
      }
      else {
        // get the last added product and show the success notification
        setNotification('success', replaceAll($t.successfullyAddedToCart, response.items.at(-1)))
      }
      // DynaTrace Real User Monitoring
      if ($dtrum) {
        [product].flat().forEach((id) => {
          const item: CartItem = response?.items.find(({ productId }) => productId === id)
          if (!item) return
          // Create an action by entering it and getting the ID
          const actionId = $dtrum.enterAction('Add To Cart')
          // Add the necessary action properties
          $dtrum.addActionProperties(
            actionId,
            null,
            null,
            {
              productsku: id // product sku added to cart
            },
            {
              productprice: item.price.amount // product price as a number
            }
          )
          // leave the action to send the data
          $dtrum.leaveAction(actionId)
        })
      }
      selectedUpsells.value = []
      pending.value = false
      error.value = false
      errorMessage.value = ''
      errorTitle.value = ''
    }
    catch (err) {
      assertApiError(err)
      error.value = true
      errorMessage.value = err.message
      errorTitle.value = $t.somethingWentWrong
      // basket has expired in the back end for guest user so reset it
      // re-addition to the basket to be handled by checkout team, ticket no: GLOBAL15-44946
      const errId = err?.errorId
      if (errId) {
        switch (errId) {
          // CAB/HOTFIX: Per https://digital.vfc.com/jira/browse/GLOBAL15-59506
          // Users are encountering this error regularly in PROD right now
          // this costing TBL a lot of money, we'll need to re-evaluate
          case ApiErrorCode.INVALID_CUSTOMER:
            clearAndRecreateCart()
            break
          case ApiErrorCode.CART_NOT_FOUND:
            await recreateCart()
            add({ product })
            break
          case ApiErrorCode.ITEM_OOS:
            error.value = true
            errorMessage.value = $t.thisItemCouldNotBeMovedToCart
            throw err
          case ApiErrorCode.ITEMS_OOS:
            // GLOBAL15-69442 propagating this error up in order to retry on PDP
            error.value = true
            errorMessage.value = err.message
            throw err
          case ApiErrorCode.ADDED_MAX_QUANTITY:
            errorMessage.value = getMaxAllowedQtyMessage(err)
            errorTitle.value = ''
            break
          case ApiErrorCode.GWP_PRODUCT:
            toast.add({
              props: {
                title: $t.somethingWentWrong,
                message: $t.itemCannotAddedToCart,
                type: 'error'
              }
            })
            error.value = false
            throw err
          default:
            error.value = true
            errorMessage.value = err.message
            throw err
        }
      }
      else {
        console.warn(err) // in case we are not handling the error above, we need more information about it
      }
      pending.value = false
    }
  }

  /**
   * remove item from the cart via api and update the store
   * uses store basketId for users cart
   */
  const remove = async (itemId: string, upc?: string) => {
    const { $gtm } = useNuxtApp()
    try {
      pending.value = true
      const response = await cart.$removeItem({
        cartId: basketId.value,
        itemId,
        upc
      })

      const product = items.value.find((item) => item.id === itemId)
      $gtm.push('cart.onRemoveFromCart', {
        id: basketId.value,
        currency: currency.value,
        items: [product]
      })

      // Remove cart item from GTM persistent storage
      if (product) removeGtmCartProductsMap(product.productId, product.masterId)

      updateCartData(response)
      processApproachingPromotions(response)
    }
    catch (err) {
      if (isErrorInvalidCustomer(err))
        clearAndRecreateCart()
      if (isErrorCartNotFound(err)) {
        await recreateCart()
        return remove(itemId)
      }
      // Need to know more specific error message
      throw new Error('Unable to remove item from cart. Please try again later', {
        cause: err
      })
    }
    finally {
      pending.value = false
    }
  }

  const applyReward = async (rewardAmount: string) => {
    try {
      pending.value = true
      const response = await cart.$applyReward(basketId.value, { appliedLoyaltyVoucher: rewardAmount })
      updateCartData(response)
    }
    catch (err) {
      assertApiError(err)
    }
    finally {
      pending.value = false
    }
  }

  /**
   * get payment method data from api and update the store
   * uses store basketId to get the payment method
   */
  const getPaymentMethod = async () => {
    try {
      if (basketId.value) {
        const response = await cart.$getPaymentMethods(basketId.value)
        paymentMethods.value = response
      }
    }
    catch (err) {
      console.error(err)
    }
  }

  /**
   * @param paymentMethodId - Payment Method Code
   */
  const postPaymentInstruments = async (paymentMethodId: PaymentMethodCode, extraPayload?: any) => {
    try {
      pending.value = true
      await getApplicableShippingMethods()

      const response = await cart.$addPaymentInstrument(basketId.value, {
        payment_method_id: paymentMethodId,
        ...(extraPayload || {})
      })

      updateCartData(response, { emitUpdateEvent: false })
      error.value = false
      errorMessage.value = ''

      return response
    }
    catch (err) {
      assertApiError(err)
      error.value = true
      errorMessage.value = err.message
      const level: NotificationLevel = {
        [ApiErrorCode.PAYMENT_ERROR]: 'top',
        [ApiErrorCode.PAYMENT_FRAUD_VERIFICATION_EXCEPTION]: 'top',
        [ApiErrorCode.CANT_SHIP_THERE]: 'top',
        [ApiErrorCode.GC_INVALID2]: 'input',
        [ApiErrorCode.GC_ALREADY_APPLIED]: 'input',
        [ApiErrorCode.GC_NOT_ALLOWED]: 'input',
        [ApiErrorCode.GC_ZERO_BALANCE]: 'input'
      }[err.errorId]

      setNotification('error', err.message, level)
    }
    finally {
      pending.value = false
    }
  }

  /**
   * Delete payment instrument.
   * @param {string} payment_instrument_id
   */
  const deletePaymentInstruments = async (payment_instrument_id: string) => {
    try {
      pending.value = true
      await getApplicableShippingMethods()

      const response = await cart.$deletePaymentInstrument(basketId.value, {
        payment_instrument_id
      })

      updateCartData(response, { emitUpdateEvent: false })
      error.value = false
      errorMessage.value = ''

      return response
    }
    catch (err) {
      console.warn(err)
    }
    finally {
      pending.value = false
    }
  }

  // full list of error conditions can be found here: https://digital.vfc.com/wiki/display/ECOM15/BOPIS+Error+Handling+-+List+of+Cases
  const handleCustomerNotifications = (customerNotifications: CustomerNotification[]) => {
    customerNotifications.forEach((notification: CustomerNotification) => {
      if ([
        'PickupToSthTransition',
        'PickupToStsTransition',
        'StsToSthTransition',
        'StsToPickupTransition',
        'STHToPickupTransition',
        'OrderShippingMethodDowngraded'
      ].includes(notification.type))
        setNotification('info', notification.message || $t.itemDeliveryMethodChanged, 'top')
    })
  }

  const setItemQty = async (item: CartItem, qty: number) => {
    let cartItem = <CartItem>{}
    const { $gtm } = useNuxtApp()
    try {
      pending.value = true
      const payload: UpdateItemPayload = {
        cartId: basketId.value,
        itemId: item.id,
        maxQty: item.maxQty,
        pdpUrl: item.pdpUrl,
        productId: item.productId,
        qty,
        upc: item.upc
      }

      // need this check because Mule API does not accept empty string for update item call
      if (item.recipeId)
        payload.recipeId = item.recipeId

      let params = {}
      if (item.selectedShippingOption?.storeInfo?.id) {
        payload.storeId = item.selectedShippingOption.storeInfo?.id
        params = { action: 'pickup', favStoreId: payload.storeId }
      }
      // get and use applicableShippingMethods since the updateItem call is not returning it
      await getApplicableShippingMethods()

      const response: any = await cart.$updateItem(basketId.value, payload, params)
      cartItem = response.items.find(({ productId }) => productId === item.productId)

      const diff = Math.abs(item.qty - qty)
      qty > item.qty
        ? $gtm.push('cart.onAddToCart', response, [item], {
          breadcrumbs: deserializeAnalyticsBreadcrumbs(history.state.breadcrumbs),
          category: history.state.category,
          searchTerm: history.state.searchTerm,
          quantity: diff
        })
        : $gtm.push('cart.onRemoveFromCart', {
          id: basketId.value,
          currency: currency.value,
          items: [item]
        }, { quantity: diff })

      updateCartData(response)
      processApproachingPromotions(response)

      useToaster().add({
        props: {
          title: $t.success,
          message: replaceAll($t.cartItemQuantityEdited, { name: item.name, qty }),
          type: 'success'
        }
      })

      error.value = false
      errorMessage.value = ''
    }
    catch (err) {
      assertApiError(err)
      if (isErrorInvalidCustomer(err))
        clearAndRecreateCart()
      if (isErrorCartNotFound(err)) {
        await recreateCart()
        return setItemQty(item, qty)
      }
      if (err.errorId === ApiErrorCode.ADDED_MAX_QUANTITY) {
        useToaster().add({
          props: {
            message: getMaxAllowedQtyMessage(err),
            type: 'error'
          }
        })
      }
      if (err.errorId === ApiErrorCode.GWP_PRODUCT) {
        setNotification('error', $t.maximumItemQtyWhileInCart, 'top')
        return
      }

      await get()
    }
    finally {
      pending.value = false
      if (cartItem.customerNotifications)
        handleCustomerNotifications(cartItem.customerNotifications)
    }
  }

  const addBillingAddress = async (address: CartStoreAddress) => {
    const response = await cart.$addBillingAddress(basketId.value, {
      ...address,
      phone: formatE164phone(address.phone, address.phoneCode),
      subscriptions: {
        newsletterConsent: false
      }
    })

    updateCartData(response, { emitUpdateEvent: false })
  }

  const moveSavedItemBackToCart = async (
    { itemId, maxQty, pdpUrl, productId, productImageURL, recipeId, qty, upc }: CartItem,
    saveForLaterId: string
  ) => {
    const auth = useAuthStore()

    try {
      pending.value = true

      if (!basketId.value) {
        const response = await cart.$create({})
        basketId.value = response?.id || ''
      }

      const data = await cart.$addItem({
        // @ts-expect-error incorrect contract type
        cartId: basketId.value,
        productId,
        qty,
        recipeId,
        maxQty,
        upc,
        saveForLater: {
          consumerId: auth.consumerId || undefined,
          itemId,
          saveForLaterId
        },
        ...(recipeId && { pdpUrl, productImageURL })
      })
      if (favoriteStoreId.value) await get({ isPatchRequired: true })
      else updateCartData(data)
      processApproachingPromotions(data)
    }
    finally {
      pending.value = false
    }
  }

  const addShippingAddress = async (
    shippingAddress: ShippingAddress[],
    recaptcha: ReturnType<typeof useRecaptcha>,
    context?: 'COLLECTION' | 'APPLE_PAY_EXPRESS',
    bid?: 'cookie',
  ) => {
    // if we don't have applicable shipping methods, we need to get them first
    if (!shippingMethods.value[0]?.applicableShippingMethods)
      await getApplicableShippingMethods()

    try {
      pendingShippingAddress.value = true
      const captchaResponse = enableShippingAddressRecaptcha ? await recaptcha.execute('login') : ''
      const payload = shippingAddress.map((sh) => ({
        ...sh,
        phone: formatE164phone(sh.phone, sh.phoneCode),
        ...captchaResponse && { recaptcha_response: captchaResponse }
      }))
      // @ts-expect-error incorrect contract type
      const response = await cart.$addShippingAddress(basketId.value, payload, { context, bid })
      updateCartData(response, { emitUpdateEvent: false, keepNotifications: itemOOSNotificationActive.value })
      if (response.customerNotifications)
        handleCustomerNotifications(response.customerNotifications)
      return response
    }
    finally {
      pendingShippingAddress.value = false
    }
  }

  const updateItemInCart = async (
    itemId: string, // id of cart item
    currentProductId,
    newProductId: string,
    selectedVariantId: string,
    upc?: string,
    giftOption?: GiftOption,
    customAmount?: { amount?: number },
    storeId?: string
  ) => {
    try {
      pending.value = true
      const productInCart = items.value.find((i) => i.masterId === newProductId)

      if (currentProductId !== newProductId && !productInCart && !giftOption) {
        const currentProductInCart = items.value.find((i) => i.masterId === currentProductId)
        if (currentProductInCart)
          await remove(currentProductInCart.id)

        // Add New Product to cart
        await add({ product: selectedVariantId, upc, suppressMiniCart: true, storeId })
      }
      else {
        const response: any = await cart.$updateItem(basketId.value, {
          cartId: basketId.value,
          itemId,
          productId: selectedVariantId,
          qty: productInCart ? productInCart.qty : giftOption ? 1 : 0,
          maxQty: productInCart ? productInCart.maxQty : giftOption ? 1 : 0,
          upc,
          ...getGiftOptionObject(giftOption)
        }, {
          ...(customAmount?.amount ? customAmount : {})
        })

        updateCartData(response)
        error.value = false
        errorMessage.value = ''
      }
      await getApplicableShippingMethods()
    }
    finally {
      pending.value = false
    }
  }

  const updateShippingMethod = async (code: ShippingMethodCode, shippingId: ShippingMethod['shippingId'] = 'me') => {
    try {
      pendingShippingAddress.value = true
      const response = await cart.$setShippingMethod({
        cartId: basketId.value,
        code,
        shippingId,
        shopperAppliedSM: true
      })
      updateCartData(response, {
        emitUpdateEvent: false,
        keepNotifications: !itemOOSNotificationActive.value && !!notification.message
      })
      error.value = false
      errorMessage.value = ''
    }
    catch (err) {
      assertApiError(err)
      setNotification('error', err.message, 'top')
    }
    finally {
      pendingShippingAddress.value = false
    }
  }

  const itemsPerShippingMethod = computed(() => {
    // sorts pickup for last so its presented on any list as last
    const sortedItems = [...items.value]

    sortedItems
      .sort((a, b) => {
        const isAPickup = isPickupOrSts(a.selectedShippingOption?.shippingMethod.code)
        const isBPickup = isPickupOrSts(b.selectedShippingOption?.shippingMethod.code)

        if (isAPickup === isBPickup)
          return 0

        return isAPickup ? 1 : -1
      })
      .sort((a, b) => sortBonusItemsFirst ? +b.bonus - +a.bonus : 0)

    const groups: CartGroupedItems = sortedItems.reduce((gp, it) => {
      const groupKey
        = isPickupOrSts(it.selectedShippingOption?.shippingMethod.code) ? 'pickup' : 'home'

      if (!gp[groupKey])
        gp[groupKey] = { items: [] }

      gp[groupKey]?.items.push(it)

      return gp
    }, {})

    return groups
  })

  const fetchSavedCartItems = async (start = 0, count = savedForLaterPageSize) => {
    try {
      savedCartPending.value = true
      const { saveForLater = [] } = !buyInStore.employeeConnected
        ? await wishlists.$getSavedForLater({ query: { start, count } })
        : { saveForLater: [] }
      const [savedItemsValue] = saveForLater as SavedItems[]
      savedItems.value = savedItems.value?.items && start > 0
        ? {
            ...savedItemsValue,
            items: [...savedItems.value.items, ...savedItemsValue.items]
          }
        : savedItemsValue
    }
    finally {
      savedCartPending.value = false
    }
  }

  const updateSavedItemInCart = async (
    itemId: string, // id of save for later item
    selectedVariantId: string,
    url?: string
  ) => {
    const auth = useAuthStore()
    try {
      pending.value = true
      const savedItem = savedItems.value.items.find((i) => i.itemId === itemId)

      await cart.$updateSavedForLater({
        productId: selectedVariantId,
        recipeId: '',
        pdpUrl: url || savedItem?.pageUrl,
        // @ts-expect-error incorrect contract type
        available: savedItem?.available,
        qty: savedItem?.qty,
        type: 'product',
        saveForLater: {
          // @ts-expect-error incorrect contract type
          consumerID: auth.consumerId,
          itemId,
          saveForLaterId: savedItems.value.saveForLaterId
        }
      })

      await fetchSavedCartItems()
      error.value = false
      errorMessage.value = ''
    }
    finally {
      pending.value = false
    }
  }

  const setFavoriteStoreId = (newValue: string) => {
    favoriteStoreId.value = newValue
  }

  const resetCachedCustomerNotifications = () => {
    cachedCustomerNotifications.value = {}
  }

  const closeCartMergedNotification = () => {
    isCartMerged.value = false
  }

  const setVerifiedShippingAddress = (verifiedAddress: ShippingAddress) => {
    verifiedShippingAddress.value = verifiedAddress
  }

  const clearVerifiedShippingAddress = () => {
    verifiedShippingAddress.value = null
  }

  return {
    add,
    addBillingAddress,
    addShippingAddress,
    applyReward,
    approachingPromotionMessage,
    athletePayment: computed(() => paymentInstruments.value.find((item) => item.payment_method_id === 'ATHLETES_PAYMENT')),
    basketId,
    cachedCustomerNotifications,
    clearNotification,
    clearVerifiedShippingAddress,
    closeCartMergedNotification,
    couponItems,
    currency,
    // defaultShippingMethod: should be ship to home
    defaultShippingMethod: computed(() => shippingMethods.value.find(({ shippingId }) => shippingId === 'me')),
    deletePaymentInstruments,
    error,
    errorMessage,
    errorTitle,
    favoriteStoreId,
    fetchSavedCartItems,
    flash,
    get,
    getApplicableShippingMethods,
    getMaxAllowedQtyMessage,
    getPaymentMethod,
    giftCards: computed(() => paymentInstruments.value.filter((item) => item.payment_method_id === 'GIFT_CARD')),
    giftCardsBalances,
    gwpMessage,
    handleFlashMessages,
    hasCollectionPointItems: computed(() => isPickupOrSts(shippingMethods.value?.[0])),
    hasCustomsProduct,
    hasDigitalItems: computed(() => shippingMethods.value.some(isDigitalDelivery)),
    hasDigitalItemsOnly: computed(() => shippingMethods.value.every(isDigitalDelivery)),
    hasPickupItemsOnly: computed(() => shippingMethods.value.every(isPickupOrSts)),
    isCartMerged,
    isZeroOrder: computed(() => totals.value.total === 0 && !paymentInstruments.value.length),
    itemOOSNotificationActive,
    items,
    itemsPerShippingMethod,
    loadingApplicableShippingMethods,
    loyaltyPayment: computed(() => paymentInstruments.value.find((item) => item.payment_method_id === 'LOYALTY_POINTS')),
    mergedCartNotificationActive,
    moveSavedItemBackToCart,
    noApplicableDeliveryNotificationActive,
    notification,
    orderPromoSummary,
    orderPromotions,
    outOfStockItems,
    paymentInstruments,
    paymentMethods,
    pending,
    pendingShippingAddress,
    postPaymentInstruments,
    productPromoSummary,
    remove,
    reset,
    resetCachedCustomerNotifications,
    resetFlashMsg,
    rewards: computed(() => paymentInstruments.value.filter((item) => loyaltyType.includes(item.payment_method_id))),
    savedCartPending,
    savedItems,
    selectedUpsells,
    setFavoriteStoreId,
    setItemQty,
    setNotification,
    setVerifiedShippingAddress,
    shippingMethods,
    shippingPromoSummary,
    totalItems,
    totals,
    updateCartData,
    updateCartId,
    updateItemGiftOption,
    updateItemInCart,
    updateItemShippingMethod,
    updateSavedItemInCart,
    updateShippingMethod,
    verifiedShippingAddress,
  }
}, {
  persist:
    storeType
      ? undefined
      : {
          storage: persistedState.localStorage,
          key: 'cart',
          paths: ['basketId', 'currency', 'totalItems', 'totals', 'items', 'shippingMethods', 'paymentMethods', 'pending', 'error', 'errorMessage', 'flash', 'favoriteStoreId', 'verifiedShippingAddress', 'cachedCustomerNotifications']
        }
})()
