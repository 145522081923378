<template>
  <div ref="elementRef" @click="handlePromotionClick">
    <div class="relative">
      <base-picture
        v-if="responsiveMedia.images"
        v-bind="responsiveMedia.images"
        :lazy
        fit="cover"
        class="full"
      />
      <base-video
        v-else-if="responsiveMedia.video"
        v-bind="responsiveMedia.video"
        ref="videoRef"
        :autoplay="!lazy"
        :controls="false"
        loop
        class="full"
        fit="cover"
      />
      <div v-style="gradient" class="absolute bottom-0 w-full" />
      <base-link
        v-if="mediaLink"
        :to="mediaLink.url"
        :target="mediaLink.targetAttribute"
        aria-hidden="true"
        tabindex="-1"
        class="absolute-0"
      />
      <div
        v-if="hasCaption"
        v-style:c="captionColor || subtitleColor"
        class="absolute right-0 mr-6"
        :class="captionPosition === 'bottom' ? 'bottom-0 mb-3' : 'top-0 mt-3'"
        style="max-width: 9.375rem"
      >
        {{ captionText }}
      </div>
    </div>
    <div
      v-style:bg="backgroundColor"
      class="flex"
      :class="[contentAlignmentSmall[textAlignmentSmall], contentAlignmentLarge[textAlignmentLarge]]"
    >
      <div
        v-style="{ 'max-w': { md: '60%', lg: '40%' } }"
        class="p-4 pt-6 space-y-4 md:p-8"
      >
        <div v-if="eyebrowText" v-style:c="eyebrowTextColor || subtitleColor" class="uppercase">
          {{ eyebrowText }}
        </div>
        <base-picture
          v-if="eyebrowLogo"
          :src="eyebrowLogo.url"
          :alt="eyebrowLogo.alt"
          fit="contain"
          style="max-height: 9.5rem"
        />
        <div class="space-y-2">
          <p v-if="overline" v-style:c="overlineColor" :class="overlineStyle" data-test-id="cms-banner-overline">
            {{ overline }}
          </p>
          <h2
            v-if="title"
            v-style:c="titleColor"
            :class="titleStyle"
            data-test-id="cms-banner-title"
          >
            <base-link v-if="mediaLink" :to="mediaLink.url" :target="mediaLink.targetAttribute">
              {{ title }}
            </base-link>
            <template v-else>
              {{ title }}
            </template>
          </h2>
          <p
            v-if="subtitle"
            v-style:c="subtitleColor"
            :class="subtitleStyle"
            data-test-id="cms-banner-text"
          >
            {{ subtitle }}
          </p>
          <cms-rich-text v-if="richText" v-style:c="richTextColor || subtitleColor" class="pointer-within" :content="{ richText }" />
        </div>
        <div
          v-if="linkType !== 'No-CTA' && targets.length"
          class="pointer-within f-col gap-4 md:flex-row md:wrap"
          :class="[buttonsAlignmentSmall[textAlignmentSmall], buttonsAlignmentLarge[textAlignmentLarge], equalTargetSizeClass]"
        >
          <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" :size />
        </div>
        <cms-rich-text
          v-if="richTextBelowTargets"
          v-style:c="richTextBelowTargetsColor || subtitleColor"
          class="pointer-within"
          :content="{ richText: richTextBelowTargets }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Responsive } from '#types/common'
import type { BannerBlockBelowContent } from '#types/components/cms/banner-block-below'
import type { TextAlignment } from '#types/components/cms/text-alignment'

const { content } = defineProps<{
  content: BannerBlockBelowContent
}>()

const {
  backgroundColor,
  captionColor,
  captionPosition,
  captionText,
  equalTargetSize,
  eyebrowLogo,
  eyebrowText,
  eyebrowTextColor,
  gradientBreakpoints = {},
  gradientStyle,
  gradientTone,
  linkType,
  media,
  mediaTarget,
  name,
  overline,
  overlineColor,
  overlineStyle,
  promotionTracking,
  richText,
  richTextBelowTargets,
  richTextBelowTargetsColor,
  richTextColor,
  subtitle,
  subtitleColor,
  subtitleStyle,
  targets = [],
  textAlignmentLarge,
  textAlignmentSmall,
  title,
  titleColor,
  titleStyle
} = content

const { size } = useAppConfig().components.cms.sharedButton
const { getMedia } = useCms()
const { elementRef, handlePromotionClick } = usePromotionTracking(promotionTracking, name)

const lazy = inject(LazyMedia)
const videoRef = ref()
const mediaLink = mediaTarget || targets[0]
const responsiveMedia = getMedia(media)

const gradientStyles = { full: 35, focused: 50, off: 0 }

const hasCaption = captionText && captionPosition !== 'off'

const gradient = {
  display: Object.entries(gradientBreakpoints).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value ? 'block' : 'none'
  }), {} as Responsive),
  bgv: `linear-gradient(to bottom, transparent, ${gradientTone || 'transparent'})`,
  h: `${gradientStyles[gradientStyle || 'off']}%`,
}

const contentAlignmentLarge: Record<TextAlignment, string> = {
  left: 'md:justify-start md:text-start',
  center: 'md:justify-center md:text-center',
  right: 'md:justify-end md:text-end'
}

const contentAlignmentSmall: Record<TextAlignment, string> = {
  left: 'justify-start text-start',
  center: 'justify-center text-center',
  right: 'justify-end text-end'
}

const buttonsAlignmentLarge: Record<TextAlignment, string> = {
  left: 'md:items-start',
  center: 'md:items-center',
  right: 'md:items-end'
}

const buttonsAlignmentSmall: Record<TextAlignment, string> = {
  left: 'items-start',
  center: 'items-center',
  right: 'items-end'
}

const equalTargetSizeClass = targets.length > 1 && [
  getValueForBreakpoint('sm', equalTargetSize) && '<md:grid <md:auto-rows-fr <md:items-stretch',
  getValueForBreakpoint('md', equalTargetSize) && '~md:grid ~md:auto-rows-fr ~md:items-stretch',
  getValueForBreakpoint('lg', equalTargetSize) && 'lg:grid lg:auto-rows-fr lg:items-stretch'
].filter(Boolean).join(' ')

onMounted(() => videoRef.value?.play())
</script>
