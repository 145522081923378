import type { DetailsItemConfig } from '#types/config/components/order/detailsItem'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    backToLink: 'flex items-center gap-1 <md:mb-2',
    billingSection: 'pt-6 lg:(pl-4 pt-0)',
    chevronIcon: '',
    containerHeading: 'mb-6 mt-8 items-center',
    creditNoteVariant: 'secondary',
    emphasizedText: 'fw-bold',
    estimatedDelivery: {
      container: 'lg:mb-1 mt-10',
      text: 'fw-bold text-base'
    },
    informationSection: 'lg:(grid cols-2 mb-6 divide-x) divide-grey-70 <lg:divide-y',
    informationSectionDetails: 'grid cols-2 gap-x-6 <md:cols-1',
    itemDescriptionDetails: 'lg:text-base',
    itemDescriptionDetailsTitle: 'pt-6',
    itemSummaryHeading: 'title-3 b-grey-50 <md:(b-t pt-8) md:(b-b pb-2)',
    orderInfoContainer: 'md:flex',
    orderInfoSection: '<md:b-grey-70 divide-grey-70',
    pricingText: '',
    shipmentContainer: '',
    shipmentText: '',
    shippingSection: 'pb-6 lg:pr-4',
    tableHeaders: 'text-center',
    trackingNumber: 'flex gap-2 <md:between',
  },
  groupReturnTrackingByShipment: false,
  notificationVariant: 'center',
  showEDD: false,
  trackReturnVariant: 'tertiary'
} satisfies RecursivePartial<DetailsItemConfig>
