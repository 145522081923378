import type { RouteLocationNormalizedLoaded } from 'vue-router'
import { camelCase } from 'scule'

import type { PageCategoryType, PageContext } from '#types/gtm'

// Given a page name such as "/about-us" It will return "About Us"
const getHumanizedPageName = (string: string) => string.split('_')[0].replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())

const getPageInfo = (
  { name, path },
  pageData: PageContext
): [category?: PageCategoryType | undefined, ...payload: any[]] => {
  // Builds the pageNameEng from alternateLinks object
  const i18n = useI18n()
  const enLocale = useRegion() === 'EMEA' ? 'en-gb' : 'en-us'
  let pageNameEng: string | undefined
  if (i18n.value.alternateLinks) {
    pageNameEng = i18n.value.alternateLinks?.[`/${name?.split('_')[0]}`]?.[enLocale]
    pageNameEng && (pageNameEng = getHumanizedPageName(pageNameEng.split('/').pop() ?? ''))
  }

  if (pageData.pageCategory && pageData.pageName)
    return [pageData.pageCategory, pageData.pageName, pageNameEng || pageData.pageName]

  /* Use $seo i18n to fetch pageName, pageNameEng, pageCategory
  If these properties (or some of them) are not declared they will be filled according to the following example:
  about-us  -> pageName:      'About Us' -> language dependent, last url path segment)
            -> pageNameEng:   'About Us' -> taken from "alternativeLinks" fetched within app.vue)
            -> pageCategory:  'Content'  -> default value for custom content pages) */

  const { $seo } = useNuxtApp()
  const seo = $seo[camelCase(name.split('_')[0])]
  if (seo) {
    const pageName = getHumanizedPageName(path?.split('/')?.pop())
    return [
      seo.category || 'Content',
      seo.name || pageName,
      seo.nameEng || pageNameEng || seo.name || pageName
    ]
  }

  // PLP, PDP, others
  const locale = useLocale()
  const routeName = name.replace(new RegExp(`_${locale}$`), '')
  return {
    'c-slug': ['PLP', getPageBreadcrumb(pageData.breadcrumbs)?.join(' - ')],
    'p-slug': ['PDP', getUrlId(path, 'Product')],
    'search': ['Search', pageData.searchType]
  }[routeName] || []
}

export default {
  onLoadPageData: (route: RouteLocationNormalizedLoaded, pageContext: PageContext = {}) => {
    const [pageCategory, pageName, pageNameEng] = getPageInfo(route, pageContext)
    const { currency } = useRuntimeConfig().public
    const locale = useLocale()
    const { suggestedPhrase } = history.state
    const formatPageName = (category, name) =>
        `${(category && name) && category !== name
            ? `${category}: ${name}`
            : category || name}`

    return {
      event: 'loadPageData',
      page: {
        addlBreadcrumbInfo: pageContext.breadcrumbs?.map(({ label, url }) => ({
          label,
          url,
          id: url?.split('-').pop()
        })),
        brand: useSiteName(),
        breadcrumb: getPageBreadcrumb(pageContext.breadcrumbs)?.join('|').toLowerCase(),
        categoryID: pageContext.categoryId,
        countryCode: useCountryCode(),
        countryLanguage: useLanguageCode().toLocaleLowerCase(),
        currencyCode: currency[locale],
        ...(pageCategory && { pageCategory }),
        ...((pageCategory && pageName) && {
          pageName: formatPageName(pageCategory, pageName)
        }),
        pageNameEng: formatPageName(pageCategory, pageContext.pageNameEng || pageNameEng),
        pageTitle: pageContext.pageTitle || document?.title,
        searchResults: pageContext.searchResults,
        searchTerm: pageContext.searchTerm || suggestedPhrase,
        searchTermAdj: pageContext.searchTermAdj,
        searchType: suggestedPhrase ? 'click' : pageContext.searchType,
        sourceId: pageContext.sourceId,
      },
      _clear: true
    }
  },
  onRouterChange: ({ fullPath, name }: RouteLocationNormalizedLoaded) => {
    return {
      'event': 'routerChange',
      'content-name': fullPath,
      'content-view-name': name
    }
  }
}
