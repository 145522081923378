<template>
  <div
    v-style:bg="backgroundColor"
    class="px-4 py-10 md:flex lg:px-10 "
    data-test-id="collection-vertical-list"
  >
    <div class="flex items-center md:w-1/2">
      <div>
        <h2 v-if="title" v-style:c="titleColor" :class="titleStyle">
          {{ title }}
        </h2>
        <p v-if="subtitle" v-style:c="subtitleColor" :class="subtitleStyle">
          {{ subtitle }}
        </p>
        <ul class="mt-4 space-y-4">
          <li v-for="(item, i) in items" :key="i">
            <base-link
              :to="item.mediaTarget.url"
              class="py-1"
              :class="textLinkStyle"
              @focus="activeIndex = i"
              @mouseover="activeIndex = i"
            >
              <span
                v-style="{
                  bgv: responsiveBgv,
                  c: textLinkColor,
                }"
                :style="{
                  'background-size': '200% 100%',
                  'background-position': activeIndex === i ? '0' : '-100%',
                  'background-clip': 'text',
                  '-webkit-text-fill-color': 'transparent',
                }"
                class="duration <md:hidden !hover:bg-left"
              >
                {{ item.title }}
              </span>
              <span v-style:c="textLinkColor" class="md:hidden">
                {{ item.title }}
              </span>
            </base-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="w-1/2 flex justify-center <md:hidden">
      <base-link
        :to="items[activeIndex].mediaTarget.url"
        aria-hidden="true"
        tabindex="-1"
        class="relative i-block"
      >
        <base-picture v-bind="getMedia(items[0].media).images" class="op-0" />
        <transition name="fade" class="duration-500">
          <base-picture
            v-bind="getMedia(items[activeIndex].media).images"
            :key="activeIndex"
            class="absolute-0 overflow-hidden rounded-lg"
          />
        </transition>
      </base-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import componentTypeConfig from './config'
import type { CollectionContent } from '#types/components/cms/collection'

const { content } = defineProps<{ content: CollectionContent<any> }>()

const {
  backgroundColor,
  subtitle,
  subtitleColor,
  subtitleStyle,
  textLinkColor,
  textLinkHoverColor,
  textLinkStyle,
  title,
  titleColor,
  titleStyle
} = content

const { getMedia } = useCms()
const { itemsProp } = componentTypeConfig[content.type!] || {}

const items = (itemsProp ? content[itemsProp] : content.items) || []

const activeIndex = ref(0)

const responsiveBgv = {
  sm: textLinkColor?.sm
    ? `linear-gradient(to right, ${textLinkHoverColor}, ${textLinkHoverColor} 50%, ${textLinkColor.sm} 50%)`
    : undefined,
  md: textLinkColor?.md
    ? `linear-gradient(to right, ${textLinkHoverColor}, ${textLinkHoverColor} 50%, ${textLinkColor.md} 50%)`
    : undefined,
  lg: textLinkColor?.lg
    ? `linear-gradient(to right, ${textLinkHoverColor}, ${textLinkHoverColor} 50%, ${textLinkColor.lg} 50%)`
    : undefined
}
</script>
