import type { BopisStoreSearchConfig } from '#types/config/components/form/bopisStoreSearch'

export default {
  brandClasses: {
    footer: 'bg-white pb-4 pt-2 <lg:(sticky bottom-0 px-6 shadow-top -mx-10) lg:(flex justify-end pt-4) <md:-mx-4',
    form: 'mt-2 space-y-2',
    heading: 'title-5',
    submitButton: 'w-full lg:w-60',
  },
  distance: '200',
  limitStores: 5,
  qtyThreshold: 10
} satisfies BopisStoreSearchConfig
