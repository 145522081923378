import { SectionContextKey } from '#content/components/cms/section/context'
import type { ContentPromotionTracking } from '#types/content'

export default (
  promotion?: ContentPromotionTracking,
  contentName = ''
) => {
  const { $gtm, $viewport } = useNuxtApp()

  const elementRef = ref<HTMLElement>()
  const isVisible = ref<boolean>(false)
  const section = inject(SectionContextKey)

  const position = computed(() => {
    const _position = section && 'position' in section ? `pos${section.position[$viewport.breakpoint]}` : null
    return [section?.name, _position, contentName].join('_')
  })

  const handlePromotionClick = () => {
    if (promotion) {
      $gtm.push('promotion.onPromotionClick', {
        creative: promotion.creativeName,
        id: promotion.promotionId,
        name: promotion.promotionName,
        position: position.value
      })
    }
  }

  const handlePromotionImpression = () => {
    if (promotion) {
      $gtm.push('promotion.onPromotionImpressions', {
        creative: promotion.creativeName,
        id: promotion.promotionId,
        name: promotion.promotionName,
        position: position.value
      })
    }
  }

  if (promotion) {
    onMounted(() => {
      if (elementRef.value instanceof HTMLElement)
        isVisible.value = useElementVisibility(elementRef)
    })

    watchOnce(isVisible, handlePromotionImpression)
  }

  return {
    elementRef,
    isVisible,
    handlePromotionClick,
    handlePromotionImpression
  }
}
