import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    content: {
      y: 'pb-8'
    }
  }
} satisfies RecursivePartial<VfConfig['panel']>
