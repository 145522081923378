<template>
  <dialog-employee-terms lazy />
  <dialog-forgot-password />
  <dialog-interests lazy />
  <dialog-welcome />
  <dialog-klarna lazy />
  <dialog-location-confirmation hide-close-button variant-close="none" />
  <dialog-loyalty-sign-up />
  <dialog-mini-cart size="lg" :class-close="miniCart.brandClasses.header?.close" />
  <dialog-sign-in />
  <dialog-sign-up />
  <dialog-three-ds-challenge variant-close="none" hide-close-button disable-outside-interaction />
</template>

<script lang="ts" setup>
const {
  DialogEmployeeTerms,
  DialogForgotPassword,
  DialogInterests,
  DialogKlarna,
  DialogLocationConfirmation,
  DialogLoyaltySignUp,
  DialogMiniCart,
  DialogSignIn,
  DialogSignUp,
  DialogThreeDsChallenge,
  DialogWelcome
} = useDialogsStore()
const { miniCart } = useAppConfig().components.dialog
</script>
