<template>
  <div class="fixed-0 z-overlay mx-a flex center" :class="{ 'md:container md:py-4': _size !== 'full' }">
    <base-dialog-root default-open @update:open="$emit('resolve')">
      <base-dialog-overlay
        v-show="_size !== 'full'"
        class="vf-vycjfu"
        style="background: #000000bf"
        data-test-id="vf-modal-backdrop"
      />
      <vf-dialog-content
        class="vf-9afuex"
        :class="[sizes[_size], classContent]"
        :style="styleContent"
        :disable-outside-interaction="disableOutsideInteraction"
      >
        <slot />
      </vf-dialog-content>
    </base-dialog-root>
  </div>
</template>

<script lang="ts" setup>
import type { CSSClass, Responsive } from '#types/common'
import type { DialogSize } from '#types/composables/useDialog'

export type Props = {
  /**
   * Size of the modal.
   */
  size?: DialogSize | Responsive<DialogSize>
  /**
   * Classes to be applied to content container.
   */
  classContent?: CSSClass
  /**
   * Styles to apply to content container.
   */
  styleContent?: string
  /**
   * Whether "close" button is shown.
   */
  hideCloseButton?: boolean
  /**
   * Whether to disable the outside interaction
   */
  disableOutsideInteraction?: boolean
}

const { size } = withDefaults(
  defineProps<Props>(),
  {
    size: 'xs'
  }
)

defineEmits<{
  resolve: []
}>()

defineSlots<{
  default?: () => any
  close?: () => any
}>()

const sizes = {
  xs: 'md:w-2/3 lg:w-1/2',
  sm: 'md:w-2/3',
  md: 'md:w-5/6',
  full: 'h-full'
}

const { $viewport } = useNuxtApp()
const _size = getValueForBreakpoint($viewport.breakpoint, size)!
</script>
