import type { SignUpConfig } from '#types/config/components/form/signUp'

export default {
  brandClasses: {
    checkboxesWrapper: 'space-y-2',
    heading: 'subtitle-1',
    helpText: 'my-2 text-xs c-grey-20',
    passwordRequirements: 'mb-2',
    submitButton: 'mt-2 w-full'
  },
  isBirthDateRequired: true,
  isLoyaltyNewsletterAllowed: true,
  isSmsOptInRequired: true,
  smsCheckboxSize: 'md'
} satisfies Partial<SignUpConfig>
