<template>
  <div ref="elementRef" class="relative" @click="handlePromotionClick">
    <div class="relative">
      <base-picture
        v-if="responsiveMedia.images"
        v-bind="responsiveMedia.images"
        :lazy
        fit="cover"
        class="w-full"
      />
      <base-video
        v-else-if="responsiveMedia.video"
        v-bind="responsiveMedia.video"
        ref="videoRef"
        fit="cover"
        :autoplay="!lazy"
        :controls="false"
        loop
        class="w-full"
      />
      <div v-style="gradient" class="absolute bottom-0 w-full" />
      <base-link
        v-if="mediaLink"
        :to="mediaLink.url"
        :target="mediaLink.targetAttribute"
        aria-hidden="true"
        tabindex="-1"
        class="absolute-0"
      />
    </div>
    <div class="mt-4 f-col px-4 space-y-2 lg:space-y-4 " :class="[contentLayoutClasses, contentAlignmentClasses]">
      <p v-if="title" v-style:c="titleColor" :class="titleStyle" data-test-id="cms-linked-card-title">
        <base-link v-if="mediaLink" :to="mediaLink.url" :target="mediaLink.targetAttribute">
          {{ title }}
        </base-link>
        <template v-else>
          {{ title }}
        </template>
      </p>
      <p
        v-if="subtitle"
        v-style:c="subtitleColor"
        :class="subtitleStyle"
        data-test-id="cms-linked-card-text"
      >
        {{ subtitle }}
      </p>
      <cms-rich-text v-if="richText" v-style:c="richTextColor || subtitleColor" class="pointer-within" :content="{ richText }" />
      <div
        v-if="linkType !== 'No-CTA' && targets.length"
        class="f-col gap-4 md:flex-row md:wrap lg:gap-6 not-first:pt-4 "
        :class="[ctaAlignmentClasses, equalTargetSizeClass]"
        :style="`--cols: repeat(${cols},minmax(0,1fr))`"
      >
        <cms-shared-button
          v-for="(target, i) in targets"
          :key="i"
          v-bind="target"
          :text-link-color="textLinkColor"
          :text-link-hover-color="textLinkHoverColor"
          :text-link-style="textLinkStyle"
          :text-link-underline="textLinkUnderline"
          :size="size"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { LinkedCardAlignment, LinkedCardContent, LinkedCardLayout } from '#types/components/cms/linked-card'
import type { Responsive } from '#types/common'

const { content, isPartOfCollection } = defineProps<{
  content: LinkedCardContent
  isPartOfCollection?: boolean
}>()

const {
  equalTargetSize,
  gradientBreakpoints = {},
  gradientStyle,
  gradientTone,
  linkType,
  media,
  mediaTarget,
  name,
  promotionTracking,
  responsiveLayout,
  richText,
  richTextColor,
  subtitle,
  subtitleColor,
  subtitleStyle,
  targets = [],
  textLinkColor,
  textLinkHoverColor,
  textLinkStyle,
  textLinkUnderline,
  title,
  titleColor,
  titleStyle,
} = content

const { size } = useAppConfig().components.cms.sharedButton
const { getMedia } = useCms()
const { elementRef, handlePromotionClick } = usePromotionTracking(promotionTracking, name)

const lazy = inject(LazyMedia)
const videoRef = ref()
const mediaLink = mediaTarget || targets[0]
const responsiveMedia = getMedia(media)

const contentLayout: Partial<Record<LinkedCardLayout, string>> = {
  below: '',
  overlay: 'absolute bottom-0 left-0 mb-4',
}

const contentAlignment: Partial<Record<LinkedCardAlignment, string>> = {
  left: '',
  center: 'items-center text-center',
}

const ctaAlignment: Record<LinkedCardAlignment, string> = {
  left: 'items-start',
  center: '<md:items-center md:justify-center'
}

const contentLayoutClasses = [
  responsiveLayout.sm?.layout && `<md:${contentLayout[responsiveLayout.sm.layout]}`,
  responsiveLayout.md?.layout && `md:${contentLayout[responsiveLayout.md.layout]}`,
  responsiveLayout.lg?.layout && `lg:${contentLayout[responsiveLayout.lg.layout]}`,
].filter(Boolean).join(' ')

const contentAlignmentClasses = [
  responsiveLayout.sm?.alignment && `<md:${contentAlignment[responsiveLayout.sm.alignment]}`,
  responsiveLayout.md?.alignment && `md:${contentAlignment[responsiveLayout.md.alignment]}`,
  responsiveLayout.lg?.alignment && `lg:${contentAlignment[responsiveLayout.lg.alignment]}`,
].filter(Boolean).join(' ')

const ctaAlignmentClasses = [
  responsiveLayout.sm?.alignment && `<md:${ctaAlignment[responsiveLayout.sm.alignment]}`,
  responsiveLayout.md?.alignment && `md:${ctaAlignment[responsiveLayout.md.alignment]}`,
  responsiveLayout.lg?.alignment && `lg:${ctaAlignment[responsiveLayout.lg.alignment]}`,
].filter(Boolean).join(' ')

const gradientStyles = { full: 35, focused: 50, off: 0 }

const gradient = {
  display: Object.entries(gradientBreakpoints).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value ? 'block' : 'none'
  }), {} as Responsive),
  bgv: `linear-gradient(to bottom, transparent, ${gradientTone || 'transparent'})`,
  h: `${gradientStyles[gradientStyle || 'off']}%`,
}

const cols = isPartOfCollection ? '1' : targets.length
const equalTargetSizeClass = targets.length > 1 && [
  getValueForBreakpoint('sm', equalTargetSize) && '<md:grid <md:cols-1 <md:auto-rows-fr',
  getValueForBreakpoint('md', equalTargetSize) && 'md:grid cols-$cols auto-rows-fr items-stretch',
  !equalTargetSize.md && getValueForBreakpoint('lg', equalTargetSize) && 'lg:grid cols-$cols auto-rows-fr items-stretch'
].filter(Boolean).join(' ')

onMounted(() => videoRef.value?.play())
</script>
