<template>
  <div ref="elementRef" v-style:bg="backgroundColor" class="md:flex" @click="handlePromotionClick">
    <div class="relative flex shrink-0" :class="mediaSize[imageWidth]">
      <div
        v-if="hasCaption"
        v-style:c="captionColor || subtitleColor"
        class="absolute right-0 mr-6"
        :class="captionPosition === 'bottom' ? 'bottom-0 mb-3' : 'top-0 mt-3'"
        style="max-width: 9.375rem"
      >
        {{ captionText }}
      </div>
      <div v-if="responsiveMediaList.length > 0" class="grid cols-2 md:flex">
        <template v-for="(mediaObject, index) in responsiveMediaList">
          <base-picture
            v-if="mediaObject.images"
            :key="`picture-${index}`"
            v-bind="mediaObject.images"
            :lazy
            fit="cover"
            class="w-full"
            :class="getMediaClasses(index)"
          />
          <base-video
            v-else-if="mediaObject.video"
            :key="`video-${index}`"
            v-bind="mediaObject.video"
            ref="videoRef"
            :autoplay="!lazy"
            :controls="false"
            loop
            class="w-full"
            fit="cover"
            :class="getMediaClasses(index)"
          />
        </template>
      </div>
      <template v-else-if="responsiveMedia">
        <base-picture
          v-if="responsiveMedia.images"
          v-bind="responsiveMedia.images"
          :lazy
          fit="cover"
          class="w-full"
        />
        <base-video
          v-else-if="responsiveMedia.video"
          v-bind="responsiveMedia.video"
          ref="videoRef"
          :autoplay="!lazy"
          :controls="false"
          loop
          class="w-full"
          fit="cover"
        />
      </template>

      <base-link
        v-if="mediaLink"
        :to="mediaLink.url"
        :target="mediaLink.targetAttribute"
        aria-hidden="true"
        tabindex="-1"
        class="absolute-0"
      />
    </div>
    <div class="w-full f-col" :class="contentAlignment">
      <div v-if="alignmentLarge.startsWith('3')" class="grid cols-2 <md:hidden">
        <template v-for="(mediaObject, index) in responsiveMediaList.slice(-2)">
          <base-picture
            v-if="mediaObject.images"
            :key="`picture-${index}`"
            v-bind="mediaObject.images"
            :lazy
            fit="cover"
            class="w-full"
          />
          <base-video
            v-else-if="mediaObject.video"
            :key="`video-${index}`"
            v-bind="mediaObject.video"
            ref="videoRef"
            :autoplay="!lazy"
            :controls="false"
            loop
            class="w-full"
            fit="cover"
          />
        </template>
      </div>
      <div
        class="w-full px-4 py-6 space-y-2 lg:px-6 lg:space-y-4"
        :class="{
          'order-first': alignmentLarge === '3-top-left' || alignmentLarge === '3-top-right',
        }"
      >
        <div v-if="eyebrowText" v-style:c="eyebrowTextColor || subtitleColor" class="uppercase">
          {{ eyebrowText }}
        </div>
        <base-picture
          v-if="eyebrowLogo"
          :src="eyebrowLogo.url"
          :alt="eyebrowLogo.alt"
          fit="contain"
          :props-img="{ class: 'w-a', style: 'max-height: 9.5rem' }"
        />
        <p v-if="overline" v-style:c="overlineColor" :class="overlineStyle" data-test-id="cms-banner-overline">
          {{ overline }}
        </p>
        <h2
          v-if="title"
          v-style:c="titleColor"
          :class="titleStyle"
          data-test-id="cms-banner-title"
        >
          <base-link v-if="mediaLink" :to="mediaLink.url" :target="mediaLink.targetAttribute">
            {{ title }}
          </base-link>
          <template v-else>
            {{ title }}
          </template>
        </h2>
        <p v-if="subtitle" v-style:c="subtitleColor" :class="subtitleStyle" data-test-id="cms-banner-text">
          {{ subtitle }}
        </p>
        <cms-rich-text v-if="richText" v-style:c="richTextColor || subtitleColor" :content="{ richText }" data-test-id="cms-banner-body" />
        <div
          v-if="linkType !== 'No-CTA' && targets.length"
          class="flex-col gap-4 i-flex md:flex-row md:wrap lg:gap-6 not-first:pt-4"
          :class="ctaClass"
        >
          <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" :size />
        </div>
        <cms-rich-text
          v-if="richTextBelowTargets"
          v-style:c="richTextBelowTargetsColor || subtitleColor"
          :content="{ richText: richTextBelowTargets }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {
  BannerSplitAlignmentLarge,
  BannerSplitAlignmentSmall,
  BannerSplitContent,
  BannerSplitMediaWidth,
  VerticalAlignment
} from '#types/components/cms/banner-split'
import type { TextAlignment } from '#types/components/cms/text-alignment'

const { content } = defineProps<{
  content: BannerSplitContent
}>()

const {
  alignmentLarge,
  alignmentSmall,
  backgroundColor,
  captionColor,
  captionPosition,
  captionText,
  equalTargetSize,
  eyebrowLogo,
  eyebrowTextColor,
  eyebrowText,
  imageWidth = '60',
  linkType,
  media,
  mediaList = [],
  mediaTarget,
  name,
  overline,
  overlineColor,
  overlineStyle,
  promotionTracking,
  richText,
  richTextColor,
  richTextBelowTargets,
  richTextBelowTargetsColor,
  subtitle,
  subtitleColor,
  subtitleStyle,
  targets = [],
  textAlignmentSmall,
  textAlignmentLarge,
  title,
  titleColor,
  titleStyle,
  verticalAlignment

} = content

const { size } = useAppConfig().components.cms.sharedButton
const { getMedia } = useCms()
const { elementRef, handlePromotionClick } = usePromotionTracking(promotionTracking, name)

const lazy = inject(LazyMedia)
const videoRef = ref()
const mediaLink = mediaTarget || targets[0]
const responsiveMedia = getMedia(media)
const responsiveMediaList = mediaList.map(getMedia)

const contentAlignmentLarge: Record<BannerSplitAlignmentLarge, string> = {
  'left-left': 'md:text-left md:order-first',
  'left-right': 'md:text-left md:order-last',
  'center-left': 'md:text-center md:order-first',
  'center-right': 'md:text-center md:order-last',
  'right-left': 'md:text-right md:order-first',
  'right-right': 'md:text-right md:order-last',
  '1-left': 'md:order-first',
  '1-right': 'md:order-last',
  '2-left': 'md:order-first',
  '2-right': 'md:order-last',
  '3-bottom-left': 'md:order-first',
  '3-bottom-right': 'md:order-last',
  '3-top-left': 'md:order-first',
  '3-top-right': 'md:order-last',
}

const contentAlignmentSmall: Partial<Record<BannerSplitAlignmentSmall, string>> = {
  left: '<md:text-left',
  center: '<md:text-center',
  right: '<md:text-right'
}

const ctaAlignmentLarge: Partial<Record<BannerSplitAlignmentLarge, string>> = {
  'left-left': 'md:justify-start',
  'left-right': 'md:justify-start',
  'center-left': 'md:justify-center',
  'center-right': 'md:justify-center',
  'right-left': 'md:justify-end',
  'right-right': 'md:justify-end'
}

const ctaAlignmentSmall: Partial<Record<BannerSplitAlignmentSmall, string>> = {
  left: '<md:items-start',
  center: '<md:items-center',
  right: '<md:items-end'
}

const textAlignmentSmallClasses: Record<TextAlignment, string> = {
  left: '<md:items-start <md:text-left',
  center: '<md:items-center <md:text-center',
  right: '<md:items-end <md:text-right'
}

const textAlignmentLargeClasses: Record<TextAlignment, string> = {
  left: 'md:items-start md:text-left',
  center: 'md:items-center md:text-center',
  right: 'md:items-end md:text-right'
}

const verticalAlignmentClasses: Record<VerticalAlignment, string> = {
  top: 'md:justify-start',
  middle: 'md:justify-center',
  bottom: 'md:justify-end'
}

const hasCaption = captionText && captionPosition !== 'off'

const mediaSize: Record<BannerSplitMediaWidth, string> = {
  60: 'md:w-3/5',
  50: 'md:w-1/2',
  40: 'md:w-2/5'
}

const getMediaClasses = (index: number) => (
  {
    '<md:col-span-2': (alignmentSmall === '3-below' || alignmentSmall === '1-below') && index === 0,
    '<md:hidden': index > +alignmentSmall.charAt(0) - 1,
    'md:hidden': (alignmentLarge.startsWith('3') && index !== 0) || (index > +alignmentLarge.charAt(0) - 1),
  }
)

const equalTargetSizeClass = targets.length > 1 && [
  getValueForBreakpoint('sm', equalTargetSize) && '<md:grid <md:auto-rows-fr <md:items-stretch',
  getValueForBreakpoint('md', equalTargetSize) && 'md:grid md:auto-rows-fr md:items-stretch',
  getValueForBreakpoint('lg', equalTargetSize) && 'lg:grid lg:auto-rows-fr lg:items-stretch'
].filter(Boolean).join(' ')

const contentAlignment = [
  contentAlignmentLarge[alignmentLarge],
  contentAlignmentSmall[alignmentSmall],
  textAlignmentSmallClasses[textAlignmentSmall],
  textAlignmentLargeClasses[textAlignmentLarge],
  verticalAlignmentClasses[verticalAlignment]
]

const ctaClass = [
  ctaAlignmentLarge[alignmentLarge],
  ctaAlignmentSmall[alignmentSmall],
  equalTargetSizeClass
]

onMounted(() => videoRef.value?.play())
</script>
