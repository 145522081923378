import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    rewardsPreviewTilePopoverContent: 'z-overlay min-w-xs',
    rewardsPreviewTilePopoverContentInner: 'bg-white p-4 c-grey-10 shadow-xs'
  },
  milestoneCount: 4,
} satisfies RecursivePartial<VfConfig['loyalty']>
