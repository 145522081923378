import type { CMSConfig } from '#types/config/components/cms'

export default {
  addButtonSize: 'tiny',
  brandClasses: {
    container: 'm-4',
    carouselControls: 'lg:-mx-7',
    carouselContainer: 'lg:px-9',
    productContainer: 'px-2',
    addButton: 'self-start',
  },
  presets: {
    size: { width: { sm: 300, md: 200 }, height: { sm: 340, md: 230 } }
  }
} satisfies CMSConfig['productRecommendations']
